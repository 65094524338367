import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { ProcessSelect } from '@components/AntFields';

import { useCreateProjectQuery } from '../../../../hooks';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

export const AddProject: React.FC = () => {
  const projectsT = useTranslation(NSKeys.projects);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const email = Form.useWatch('email', form);
  const { isLoading, mutate, status } = useCreateProjectQuery();

  React.useEffect(() => {
    if (status === 'success') {
      form.resetFields();
      setOpen(false);
    }
  }, [status]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: IFormValues) => {
    mutate({ process: { uid: values.processUid }, title: values.title });
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {projectsT.t('addButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Text type="secondary">{email?.length}</Typography.Text>
          </Space>
        }
        open={open}
        destroyOnClose
        onOk={() => form.submit()}
        okButtonProps={{
          loading: isLoading,
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={isLoading}
        onCancel={() => setOpen(false)}>
        <Form form={form} onFinish={handleSubmit} name="project_create" layout="vertical">
          <Form.Item<IFormValues>
            name="title"
            required
            label={<Typography.Text type="secondary">{projectsT.t('form.title')}</Typography.Text>}
            rules={[yupSync]}>
            <Input />
          </Form.Item>
          <Form.Item<IFormValues>
            name="processUid"
            required
            label={<Typography.Text type="secondary">{projectsT.t('form.process')}</Typography.Text>}
            rules={[yupSync]}>
            <ProcessSelect placeholder={projectsT.t('form.process')} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
