import React, { useState } from 'react';

import { theme as antTheme, Button, Divider, Flex, Popover, Skeleton, Spin, Typography } from 'antd';

import { ProcessStageStatusSchema, RetrieveProcessSchema } from '@app/models/orders';

import { StatusTag } from '@components/StatusTag';

import { useMoveToStatusQuery } from './hooks/useMoveToStatusQuery';

interface IProps {
  statusUid: string;
  orderUid: string;
  version: string;
  moveAvailable?: boolean;
  available_statuses_uids?: string[];
  processData?: RetrieveProcessSchema;
  processLoading: boolean;
}

export const ChangeStatus: React.FC<IProps> = ({
  statusUid,
  orderUid,
  version,
  available_statuses_uids = [],
  moveAvailable = true,
  processData,
  processLoading,
}) => {
  const { token } = antTheme.useToken();
  const [color, setColor] = React.useState(token.colorPrimary);
  const [name, setName] = React.useState(statusUid);
  const [isOpen, setOpen] = useState(false);
  const { mutate, status: mutateStatus } = useMoveToStatusQuery();

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      setOpen(false);
    }
  }, [mutateStatus]);

  React.useEffect(() => {
    const res = processData?.stages
      ?.map((process) => process.statuses)
      .flat()
      .find((el) => el.uid === statusUid);
    if (res) {
      setName(res.name);
      setColor(res.color);
    }
  }, [processData, statusUid]);

  const handleSelect = (data: ProcessStageStatusSchema) => {
    mutate({ order_uid: orderUid, status_uid: data.uid, version });
  };

  const handleOpen = (value: boolean) => {
    if (moveAvailable) {
      setOpen(value);
    }
  };

  const renderContent = () =>
    processLoading || mutateStatus === 'loading' ? (
      <Flex justify="center" align="center" style={{ minHeight: 300 }}>
        <Spin />
      </Flex>
    ) : (
      <Flex vertical>
        {processData?.stages.map((stage) => (
          <Flex vertical key={stage.uid}>
            <Typography.Text type="secondary" style={{ margin: '4px 0' }}>
              {stage.name}
            </Typography.Text>
            <Flex vertical gap={4} justify="start" align="baseline">
              {stage.statuses.map((status) => (
                <Button
                  disabled={status.uid === statusUid || !available_statuses_uids.some((uid) => uid === status.uid)}
                  size="small"
                  type={
                    status.uid === statusUid || !available_statuses_uids.some((uid) => uid === status.uid)
                      ? 'link'
                      : 'default'
                  }
                  style={{ textAlign: 'left' }}
                  key={status.uid}
                  onClick={(e) => {
                    handleSelect(status);
                  }}>
                  <StatusTag color={status.color} name={status.name} />
                </Button>
              ))}
            </Flex>
            <Divider plain style={{ margin: '8px 0 4px 0' }} />
          </Flex>
        ))}
      </Flex>
    );

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Popover
        open={isOpen}
        placement="bottomRight"
        onOpenChange={handleOpen}
        arrow={false}
        trigger="click"
        overlayInnerStyle={{ width: '313px', padding: 8 }}
        content={renderContent}>
        <Button
          size="small"
          onClick={(e) => {
            handleOpen(true);
          }}>
          {processLoading ? <Skeleton.Input size="small" /> : <StatusTag color={color} name={name} />}
        </Button>
      </Popover>
    </div>
  );
};
