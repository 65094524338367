import React from 'react';

import { Tooltip } from 'antd';

import { FieldType } from '@app/models/interfaces';
import { formatDate, formatDateAndTime } from '@app/services/date';
import { getValueByKeyPath } from '@app/services/getValueByKeyPath';
import { ImagesList } from '@components/ImagesList';

const renderEllipsisValue = (value: string, renderForTable?: boolean) => {
  if (!renderForTable) return value;

  return (
    <div className="ellipsis" style={{ position: 'relative' }}>
      <span
        style={{
          position: 'absolute',
          left: 0,
          right: 0,
          top: '-12px',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}>
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      </span>
    </div>
  );
};

export const getDisplayValueKey = ({
  type,
  alias,
  data,
  renderForTable,
}: {
  type: FieldType;
  alias: string;
  data: any;
  renderForTable?: boolean;
}) => {
  const value = getValueByKeyPath(data, alias);

  if (!value || (!value && value !== 0)) {
    return '';
  }
  if (type === 'datetime') {
    return renderEllipsisValue(formatDateAndTime(value), renderForTable);
  }
  if (type === 'date') {
    return renderEllipsisValue(formatDate(value), renderForTable);
  }
  if (type === 'user') {
    return renderEllipsisValue(value?.name ?? '', renderForTable);
  }
  if (type === 'catalog') {
    return renderEllipsisValue(value?.name ?? '', renderForTable);
  }
  if (type === 'recipient') {
    return renderEllipsisValue(data?.[alias]?.name ?? '', renderForTable);
  }
  if (type === 'contact') {
    return renderEllipsisValue(data?.[alias]?.name ?? '', renderForTable);
  }
  if (type === 'address') {
    return renderEllipsisValue(value?.['as_string'] ?? '', renderForTable);
  }
  if (type === 'multi_image') {
    return (
      <ImagesList
        imageSize={renderForTable ? 30 : undefined}
        imageList={value?.map(({ uid, variants }: any) => ({
          previewSrc: variants.thumbnail?.url,
          fullSrc: variants.big?.url,
        }))}
      />
    );
  }
  if (type === 'text' && renderForTable) {
    return renderEllipsisValue(value, renderForTable);
  }
  return value;
};
