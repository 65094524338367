import { Popover } from 'antd';
import styled from 'styled-components';

export const Container = styled(Popover)``;

export const Label = styled.div<{ $hasValue: boolean }>`
  position: relative;
  color: ${({ $hasValue, theme }) => ($hasValue ? '#00000073' : '')};

  &::after {
    content: ':';
    display: ${({ $hasValue }) => !$hasValue && 'none'};
  }
`;
