import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';

import { useCreateProcessModuleQuery } from '../../../../../../hooks';

import { ValidationSchema } from './data/validation';

import { IFormValues, IKind } from './interfaces';

interface IProps {
  process_uid: string;
}

const OPTIONS: { label: string; value: IKind }[] = [
  { label: 'approval', value: 'approval' }, // Согласование
];

export const AddModuleModal: React.FC<IProps> = ({ process_uid }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const { mutate, status, failureReason } = useCreateProcessModuleQuery();
  const [form] = Form.useForm<IFormValues>();
  const validationT = useTranslation(NSKeys.validation);

  React.useEffect(() => {
    if (failureReason && form) {
      const formValues = form.getFieldsValue();
      const { pointerErrors } = getFormErrorsFromResponse(formValues, failureReason, validationT.t);

      form.setFields([{ name: ['module', 'kind'], errors: pointerErrors[0]?.errors }]);
    }
  }, [failureReason]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      try {
        await ValidationSchema(validationT.t).validateSyncAt(field, { module: { kind: value } });
      } catch (error: any) {
        return Promise.reject(error?.message);
      }
    },
  };

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  const handleSubmit = (values: IFormValues) => {
    console.log(values?.module?.kind);
    mutate({
      uid: process_uid,
      moduleData: {
        module: {
          kind: values.module.kind,
        },
      },
    });
  };

  return (
    <div style={{ display: 'inline-block', marginTop: 20 }}>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {ordersT.t('addModuleTitle')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {ordersT.t('addModuleTitle')}
            </Typography.Title>
          </Space>
        }
        open={open}
        destroyOnClose
        okButtonProps={{
          form: 'create_field',
          htmlType: 'submit',
          loading: status === 'loading',
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={status === 'loading'}
        onCancel={() => setOpen(false)}>
        <div style={{ margin: '40px 0' }}>
          <Form<IFormValues>
            form={form}
            onFinish={handleSubmit}
            name="create_field"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{ type: OPTIONS[0].value }}>
            <Form.Item<IFormValues> label="Результат" name={['module', 'kind']} rules={[yupSync]}>
              <Select
                options={OPTIONS.map((el) => ({
                  label: ordersT.t(`fieldsForm.kind.${el.label}` as any),
                  value: el.value,
                }))}
              />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
