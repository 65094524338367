import React, { useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, Modal, Select, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useCreateProcessStageQuery } from '../../../../../../hooks';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  process_uid: string;
  total: number;
}

export const AddStageModal: React.FC<IProps> = ({ process_uid, total }) => {
  const ordersT = useTranslation(NSKeys.orders);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const { mutate, status } = useCreateProcessStageQuery();
  const [form] = Form.useForm<IFormValues>();
  const validationT = useTranslation(NSKeys.validation);

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = (values: IFormValues) => {
    mutate({
      uid: process_uid,
      data: {
        name: values.name,
        position: total + 1,
      },
    });
  };

  return (
    <div style={{ display: 'inline-block', marginTop: 20 }}>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {ordersT.t('addStageButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {ordersT.t('addStageTitle')}
            </Typography.Title>
          </Space>
        }
        open={open}
        destroyOnClose
        okButtonProps={{
          form: 'create_field',
          htmlType: 'submit',
          loading: status === 'loading',
        }}
        okText={commonT.t('buttonAdd')}
        confirmLoading={status === 'loading'}
        onCancel={() => setOpen(false)}>
        <div style={{ margin: '40px 0' }}>
          <Form
            form={form}
            onFinish={handleSubmit}
            name="create_field"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 16 }}
            initialValues={{}}>
            <Form.Item<IFormValues>
              name="name"
              required
              label={<Typography.Text type="secondary">{ordersT.t('fieldName')}</Typography.Text>}
              rules={[yupSync]}>
              <Input />
            </Form.Item>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
