import React, { useRef, useState } from 'react';

import { Loader } from '@googlemaps/js-api-loader';
import { AutoComplete, Collapse, ColProps, Form, FormInstance, Input } from 'antd';

import { InputStatus } from 'antd/es/_util/statusUtils';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { IParsedAddressValues } from '@components/AntFields/components/GoogleAutocomplete/interface';
import { getAddressObject } from '@components/AntFields/components/GoogleAutocomplete/services';
let placesApiClient: google.maps.PlacesLibrary | undefined;

import { PLACE_SERVICE_FIELDS } from './constants';
import { Container } from './styles';

async function getGoogleMapsPlacesApiClient(): Promise<google.maps.PlacesLibrary> {
  if (placesApiClient) {
    return placesApiClient;
  }
  const loader = new Loader({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_API_TOKEN || '',
    version: 'weekly',
  });
  placesApiClient = await loader.importLibrary('places');
  return placesApiClient;
}

export interface IProps {
  form: FormInstance;
  wrapperCol?: ColProps;
  rules: any;
  hasError: boolean;
  defaultOpen?: boolean;
  disabled?: boolean;
  required?: boolean;
  provider?: string;
  label?: string;
  name?: string | string[];
}

const TAB_ID = '1';

export const GoogleAutocomplete: React.FC<IProps> = ({
  disabled = false,
  defaultOpen = true,
  label,
  name,
  wrapperCol = { span: 24 },
  provider = 'google',
  required = false,
  ...props
}) => {
  const [fetchingPredictions, setFetchingPredictions] = useState(false);
  const [fetchingDetails, setFetchingDetails] = useState(false);
  const [activeKey, setActiveKey] = useState<string[]>([TAB_ID]);
  const [suggestions, setSuggestions] = useState<google.maps.places.AutocompletePrediction[]>([]);
  const [placeDetail, setPlaceDetail] = useState<google.maps.places.PlaceResult | null>(null);
  const { data } = useLocalSelector((state) => state.currentSession);
  const [status, setSTatus] = useState<InputStatus>('');
  const sessionTokenRef = useRef<google.maps.places.AutocompleteSessionToken>();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const autocompleteT = useTranslation(NSKeys.autocomplete);
  const validationT = useTranslation(NSKeys.validation);
  const [country, setCountry] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [region, setRegion] = useState('');
  const [city, setCity] = useState('');
  const [autoCompleteInput, setAutoCompleteInput] = useState('');

  const getName = (value: string) => (name ? (Array.isArray(name) && name.length ? [...name, value] : value) : value);

  React.useEffect(() => {
    const provider_address_uid = props.form.getFieldValue(getName('provider_address_uid'));
    if (provider_address_uid) {
      getInitialValues(provider_address_uid);
    }
    if (!defaultOpen) {
      setActiveKey([]);
    }
  }, []);

  const handleChange = (newValue: string) => {
    setAutoCompleteInput(newValue);
    setPlaceDetail(null);
    props.form.setFieldValue(getName('provider_address_uid'), '');
    props.form.setFieldValue(getName('street_name'), '');
    props.form.setFieldValue(getName('street_number'), '');
    props.form.setFieldValue(getName('place_name'), '');
    props.form.setFieldValue(getName('provider'), '');
    setCity('');
    setRegion('');
    setPostalCode('');
    setCountry('');

    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    if (!newValue || newValue.trim().length <= 3) {
      setSuggestions([]);
      return;
    }

    timeoutRef.current = setTimeout(async () => {
      const places = await getGoogleMapsPlacesApiClient();

      if (!sessionTokenRef.current) {
        sessionTokenRef.current = new places.AutocompleteSessionToken();
      }

      setFetchingPredictions(true);
      // @see https://developers.google.com/maps/documentation/javascript/place-autocomplete
      new places.AutocompleteService().getPlacePredictions(
        {
          input: newValue,
          sessionToken: sessionTokenRef.current,
          language: data?.scope?.settings?.address_language_code,
        },
        (predictions, status) => {
          setFetchingPredictions(false);
          if (status === places.PlacesServiceStatus.ZERO_RESULTS) {
            setSuggestions([]);
            return;
          }
          if (status !== places.PlacesServiceStatus.OK || !predictions) {
            alert(status);
            return;
          }
          setSuggestions(predictions);
        }
      );
    }, 350);
  };

  const handleSuggestionSelected = async (option: { label: string; value: string }) => {
    setSuggestions([]);
    const places = await getGoogleMapsPlacesApiClient();
    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    setFetchingDetails(true);

    // @see https://developers.google.com/maps/documentation/javascript/places
    new places.PlacesService(document.getElementById('googlemaps-attribution-container') as HTMLDivElement).getDetails(
      {
        placeId: option.value,
        fields: PLACE_SERVICE_FIELDS,
        language: data?.scope?.settings?.address_language_code,
        sessionToken,
      },
      (place, status) => {
        setFetchingDetails(false);
        if (status === places.PlacesServiceStatus.OK) {
          setPlaceDetail(place);
          const { country, city, postal_code, region, street_name, street_number } = getAddressObject(
            place?.address_components
          );

          setCity(city);
          setRegion(region);
          setPostalCode(postal_code);
          setCountry(country);
          setAutoCompleteInput(place?.formatted_address || '');
          props.form.setFieldValue(getName('provider_address_uid'), place?.place_id);
          props.form.setFieldValue(getName('street_name'), street_name);
          props.form.setFieldValue(getName('street_number'), street_number);
          props.form.setFieldValue(getName('place_name'), '');
          props.form.setFieldValue(getName('provider'), provider);
        }
      }
    );
  };

  const getInitialValues = async (init_provider_address_uid: string) => {
    // setValue('autoComplete');
    setSuggestions([]);
    const places = await getGoogleMapsPlacesApiClient();
    const sessionToken = sessionTokenRef.current;
    sessionTokenRef.current = undefined;

    setFetchingDetails(true);

    // @see https://developers.google.com/maps/documentation/javascript/places
    new places.PlacesService(document.getElementById('googlemaps-attribution-container') as HTMLDivElement).getDetails(
      {
        placeId: init_provider_address_uid,
        fields: PLACE_SERVICE_FIELDS,
        language: data?.scope?.settings?.address_language_code,
        sessionToken,
      },
      (place, status) => {
        setFetchingDetails(false);
        if (status === places.PlacesServiceStatus.OK) {
          setPlaceDetail(place);
          const street_name = props.form.getFieldValue(getName('street_name'));
          const street_number = props.form.getFieldValue(getName('street_number'));
          const place_name = props.form.getFieldValue(getName('place_name'));
          const { country, city, postal_code, region } = getAddressObject(place?.address_components);
          setAutoCompleteValue({ country, city, postal_code, region, street_name, street_number, place_name });

          setCity(city);
          setRegion(region);
          setPostalCode(postal_code);
          setCountry(country);
        }
      }
    );
  };

  const handleFieldsChange = () => {
    const street_name = props.form.getFieldValue(getName('street_name'));
    const street_number = props.form.getFieldValue(getName('street_number'));
    const place_name = props.form.getFieldValue(getName('place_name'));
    const { country, city, postal_code, region } = getAddressObject(placeDetail?.address_components);
    setAutoCompleteValue({ street_number, street_name, place_name, city, country, postal_code, region });
  };

  const setAutoCompleteValue = (data: IParsedAddressValues) => {
    const { city, country, postal_code, region, street_number, street_name, place_name } = data;

    setAutoCompleteInput(
      [country, postal_code, region, city, street_name, street_number, place_name].filter((el) => !!el).join(', ')
    );
  };

  return (
    <Container>
      <Collapse
        ghost
        collapsible="icon"
        destroyInactivePanel={false}
        expandIconPosition="end"
        activeKey={activeKey}
        size="small"
        onChange={(key) => setActiveKey(key as string[])}
        items={[
          {
            key: TAB_ID,
            label: (
              <Form.Item
                // name={getName('autoComplete')}
                label={label || autocompleteT.t('label')}
                wrapperCol={wrapperCol}
                required={required}
                style={{ marginLeft: 12 }}
                rules={
                  props.rules || [
                    {
                      required: true,
                      message: validationT.t('required'),
                    },
                  ]
                }>
                <AutoComplete
                  disabled={disabled}
                  onSearch={handleChange}
                  placeholder={autocompleteT.t('placeholder')}
                  options={suggestions?.map((suggestion) => ({
                    label: suggestion.description,
                    value: suggestion.place_id,
                  }))}
                  onSelect={(e, option) => handleSuggestionSelected(option)}
                  onBlur={() => setSTatus(placeDetail ? '' : 'error')}
                  onFocus={() => setSTatus('')}
                  status={status}
                  value={autoCompleteInput}
                />
              </Form.Item>
            ),
            children: (
              <div>
                <div style={{ display: 'none' }}>
                  <Form.Item name={getName('provider_address_uid')} label="provider_address_uid">
                    <Input disabled={true} />
                  </Form.Item>
                  <Form.Item name={getName('provider')} label="provider">
                    <Input disabled={true} />
                  </Form.Item>
                </div>
                <Form.Item label={autocompleteT.t('country')}>
                  <Input disabled={true} value={country} />
                </Form.Item>
                <Form.Item label={autocompleteT.t('postal_code')}>
                  <Input disabled={true} value={postalCode} />
                </Form.Item>
                <Form.Item label={autocompleteT.t('region')}>
                  <Input disabled={true} value={region} />
                </Form.Item>
                <Form.Item label={autocompleteT.t('city')}>
                  <Input disabled={true} value={city} />
                </Form.Item>
                <Form.Item name={getName('street_name')} label={autocompleteT.t('street')} rules={props.rules}>
                  <Input disabled={disabled} max={300} onChange={handleFieldsChange} />
                </Form.Item>
                <Form.Item name={getName('street_number')} label={autocompleteT.t('house')} rules={props.rules}>
                  <Input disabled={disabled} max={150} onChange={handleFieldsChange} />
                </Form.Item>
                <Form.Item name={getName('place_name')} label={autocompleteT.t('office')}>
                  <Input disabled={disabled} max={150} onChange={handleFieldsChange} />
                </Form.Item>
              </div>
            ),
          },
        ]}
      />

      <div id="googlemaps-attribution-container"></div>
    </Container>
  );
};
