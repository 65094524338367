import React, { FC, useState } from 'react';

import { Button, Flex, Layout, Row, Space, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { TableParams } from '@app/models/interfaces';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { useGetProjectsQuery } from '../../hooks';

import { AddProject } from './components/AddProject';
import { ListTypeSwitch } from './components/ListTypeSwitch';
import { TableActionMenu } from './components/TableActionMenu';
import { UpdateProject } from './components/UpdateProject';
import { getBaseColumns } from './data/constants';

import { ListType } from './interfaces';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};
export const List: FC = () => {
  const navigate = useNavigate();
  const commonT = useTranslation(NSKeys.common);
  const projectsT = useTranslation(NSKeys.projects);
  const [searchParams] = useSearchParams();
  const viewType = searchParams.get('view');
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const { data, isLoading } = useGetProjectsQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
    process_uid: '',
    is_archived: viewType === ListType.ARCHIVED,
  });
  const { token } = theme.useToken();

  const COLUMNS = getBaseColumns(token.colorPrimaryBg);

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <Flex gap={10} align="center">
            <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
              {projectsT.t('title')}
            </Typography.Title>

            <ListTypeSwitch />
          </Flex>
          {currentSession?.ordersContext?.scope?.$permissions?.create_projects && (
            <Space size="middle">
              <AddProject />
            </Space>
          )}
        </Row>
      </Layout.Header>

      <Container>
        <Table
          loading={isLoading}
          dataSource={data?.items || []}
          rowKey="uid"
          className="tableClassname"
          style={{ marginTop: 24 }}
          onRow={(data) => {
            return {
              onClick: () => navigate(`${data.uid}`),
            };
          }}
          rowClassName="cursorPointer"
          columns={[
            ...COLUMNS.map((el) => ({ ...el, title: projectsT.t(el.title as any) })),
            {
              key: 'action',
              width: 60,
              fixed: 'right',
              onCell: () => {
                return {
                  onClick: (e) => console.log(e.stopPropagation()),
                };
              },
              render: (_, cell) => cell?.$permissions?.manage && <TableActionMenu cell={cell} />,
            },
          ]}
          key="uid"
          pagination={{
            ...tableParams.pagination,
            total: data?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomCenter'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
          onChange={(pagination, filters, sorter) => {
            setTableParams({
              pagination,
              filters,
              ...sorter,
            });
          }}
          scroll={{ x: true }}
        />
      </Container>
    </>
  );
};
