import { IOrderApprovalChainsParams, IUserGroupUsersParams } from '@app/models/interfaces';
import {
  ApplyResetSchema,
  CreateInvitationDTO,
  CreateOrderApprovalChainSchema,
  CreateResetSchema,
  CreateRoleSchema,
  CreateUserGroupSchema,
  InvitationDTO,
  ListRolesSchema,
  ManageUsersUserGroupDTO,
  OrderApprovalChainUsageDTO,
  RecordsListGenericSchemaListRolesSchema,
  RecordsListGenericSchemaRetrieveGroupUserSchema,
  RecordsListGenericSchemaRetrieveInvitationSchema,
  RecordsListGenericSchemaRetrieveOrderApprovalChainSchema,
  RecordsListGenericSchemaRetrieveUserGroupSchema,
  RecordsListGenericSchemaRetrieveUserSchema,
  RegisterInvitedUserSchema,
  RegisterUserSchema,
  RetrieveOrderApprovalChainSchema,
  RetrieveResetSchema,
  RetrieveRoleSchema,
  RetrieveScopeSchema,
  RetrieveSelfUserSchema,
  RetrieveUserGroupSchema,
  RetrieveUserSchema,
  RoleConfig,
  SignInResponseSchema,
  SignInSchema,
  UpdateRoleSchema,
  UpdateScopeSchema,
  UpdateSelfPasswordSchema,
  UpdateSelfUserSchema,
  UpdateUserGroupSchema,
  UpdateUserSchema,
  UserNotificationSettings,
} from '@app/models/users';
import { handleRequest, httpClient } from '@app/services/httpClient';
import { objectToSearchParams } from '@app/services/objectToSearchParams';

import { IProps } from '../interfaces';

class Users {
  private readonly endPoint: string;

  constructor({ baseEndPoint }: IProps) {
    this.endPoint = baseEndPoint;
  }

  async getAuth(): Promise<RetrieveSelfUserSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/auth`));
  }

  async signIn(data: SignInSchema): Promise<SignInResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/auth/sign-in`, data));
  }

  async postRegisterInvited(data: RegisterInvitedUserSchema): Promise<SignInResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/auth/register-invited`, data));
  }

  async getCheckEmailOccupancy(email: string): Promise<{ is_occupied: boolean }> {
    return handleRequest(httpClient.get(`${this.endPoint}/auth/check-email-occupancy?email=${email}`));
  }

  async postResets(data: CreateResetSchema): Promise<RetrieveResetSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/resets`, data));
  }

  async postConfirmations(): Promise<string> {
    return handleRequest(httpClient.post(`${this.endPoint}/confirmations`));
  }

  async postConfirmationId(id: string): Promise<string> {
    return handleRequest(httpClient.post(`${this.endPoint}/confirmations/${id}`));
  }

  async postAuthRegister(data: RegisterUserSchema): Promise<SignInResponseSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/auth/register`, data));
  }

  async getScopes(): Promise<RetrieveScopeSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/scopes`));
  }

  async putScopes(data: UpdateScopeSchema): Promise<UpdateScopeSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/scopes`, data));
  }

  async putScopeLogo(data: any): Promise<Blob | MediaSource> {
    return handleRequest(
      httpClient.put(`${this.endPoint}/scopes/logo`, data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    );
  }

  async putFavicon(data: any): Promise<Blob | MediaSource> {
    return handleRequest(
      httpClient.put(`${this.endPoint}/scopes/favicon`, data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    );
  }

  async putPassword(data: UpdateSelfPasswordSchema): Promise<RetrieveScopeSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/auth/password`, data));
  }

  async putUserAuth(data: UpdateSelfUserSchema): Promise<RetrieveUserSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/auth`, data));
  }

  async postResetsUuid(uuid: string, data: ApplyResetSchema): Promise<string> {
    return handleRequest(httpClient.post(`${this.endPoint}/resets/${uuid}`, data));
  }

  async getUsers(params: {
    offset: number;
    limit: number;
    group_uid_ne?: string;
    username?: string;
    name__contains?: string;
  }): Promise<RecordsListGenericSchemaRetrieveUserSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/users${objectToSearchParams(params)}`));
  }

  async getUserInvitations(params: {
    offset: number;
    limit: number;
  }): Promise<RecordsListGenericSchemaRetrieveInvitationSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/invitations${objectToSearchParams(params)}`));
  }

  async getDeleteInvitations(invitation_uuid: string): Promise<string> {
    return handleRequest(httpClient.delete(`${this.endPoint}/invitations/${invitation_uuid}`));
  }

  async getUser(id: string): Promise<RetrieveUserSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/users/${id}`));
  }

  async putUser(id: string, data: UpdateUserSchema): Promise<RetrieveUserSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/users/${id}`, data));
  }

  async getUserPhoto(pk: number): Promise<Blob | MediaSource> {
    return handleRequest(httpClient.get(`${this.endPoint}/users/${pk}/photo`, { responseType: 'blob' }));
  }
  async putUserPhoto(data: any): Promise<Blob | MediaSource> {
    return handleRequest(
      httpClient.put(`${this.endPoint}/auth/photo`, data, {
        headers: { 'content-type': 'multipart/form-data' },
      })
    );
  }

  async postInvitations(data: CreateInvitationDTO[]): Promise<InvitationDTO[]> {
    return handleRequest(httpClient.post(`${this.endPoint}/invitations`, data));
  }

  async getInvitations(id: string): Promise<InvitationDTO> {
    return handleRequest(httpClient.get(`${this.endPoint}/invitations/${id}`));
  }

  async getRoles(): Promise<RecordsListGenericSchemaListRolesSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/roles`));
  }

  async getRoleConfig(): Promise<RoleConfig> {
    return handleRequest(httpClient.get(`${this.endPoint}/roles/config`));
  }

  async putRole(id: number, data: UpdateRoleSchema): Promise<RetrieveRoleSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/roles/${id}`, data));
  }

  async postRole(data: CreateRoleSchema): Promise<ListRolesSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/roles`, data));
  }

  async getUserGroups(params: {
    name?: string;
    order_by?: string;
    offset: number;
    limit: number;
  }): Promise<RecordsListGenericSchemaRetrieveUserGroupSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/user-groups${objectToSearchParams(params)}`));
  }

  async getUserGroup(user_group_uid: string): Promise<RetrieveUserGroupSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/user-groups/${user_group_uid}`));
  }

  async getUserGroupUsers(params: IUserGroupUsersParams): Promise<RecordsListGenericSchemaRetrieveGroupUserSchema> {
    return handleRequest(
      httpClient.get(
        `${this.endPoint}/user-groups/${params.user_group_uid}/users${objectToSearchParams({
          offset: params.offset,
          limit: params.limit,
        })}`
      )
    );
  }

  async postUserGroups(data: CreateUserGroupSchema): Promise<RetrieveUserGroupSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/user-groups`, data));
  }

  async putUsersToUserGroup(user_group_uid: string, data: ManageUsersUserGroupDTO): Promise<string> {
    return handleRequest(httpClient.put(`${this.endPoint}/user-groups/${user_group_uid}/users`, data));
  }

  async deleteUsersFromUserGroup(user_group_uid: string, user_id: number): Promise<string> {
    return handleRequest(httpClient.delete(`${this.endPoint}/user-groups/${user_group_uid}/users/${user_id}`));
  }

  async deleteRole(role_id: string): Promise<string> {
    return handleRequest(httpClient.delete(`${this.endPoint}/roles/${role_id}`));
  }

  async putUserGroups(user_group_uid: string, body: UpdateUserGroupSchema): Promise<RetrieveUserGroupSchema> {
    return handleRequest(httpClient.put(`${this.endPoint}/user-groups/${user_group_uid}`, body));
  }

  async deleteUserGroup(user_group_uid: string): Promise<string> {
    return handleRequest(httpClient.delete(`${this.endPoint}/user-groups/${user_group_uid}`));
  }

  async getOrderApprovalChains(
    params: IOrderApprovalChainsParams
  ): Promise<RecordsListGenericSchemaRetrieveOrderApprovalChainSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/order-approval-chains${objectToSearchParams(params)}`));
  }

  async getOrderApprovalChain(approval_chain_uid: string): Promise<RetrieveOrderApprovalChainSchema> {
    return handleRequest(httpClient.get(`${this.endPoint}/order-approval-chains/${approval_chain_uid}`));
  }

  async getOrderApprovalChainUsage(order_chain_approval_uid: string): Promise<OrderApprovalChainUsageDTO> {
    return handleRequest(httpClient.get(`${this.endPoint}/order-approval-chains/${order_chain_approval_uid}/usage`));
  }

  async deleteOrderApprovalChain(order_chain_approval_uid: string): Promise<string> {
    return handleRequest(httpClient.delete(`${this.endPoint}/order-approval-chains/${order_chain_approval_uid}`));
  }

  async createOrderApprovalChain(data: CreateOrderApprovalChainSchema): Promise<RetrieveOrderApprovalChainSchema> {
    return handleRequest(httpClient.post(`${this.endPoint}/order-approval-chains`, data));
  }

  async putOrderApprovalChain(approval_chain_uid: string, data: CreateOrderApprovalChainSchema): Promise<string> {
    return handleRequest(httpClient.put(`${this.endPoint}/order-approval-chains/${approval_chain_uid}`, data));
  }

  async cloneOrderApprovalChains(approval_chain_uid: string, name: string): Promise<RetrieveOrderApprovalChainSchema> {
    return handleRequest(
      httpClient.post(`${this.endPoint}/order-approval-chains/${approval_chain_uid}/clone`, {
        name,
      })
    );
  }

  async putSettingsNotifications(userId: string, data: UserNotificationSettings): Promise<UserNotificationSettings> {
    return handleRequest(httpClient.put(`${this.endPoint}/users/${userId}/settings/notifications`, data));
  }
}

export default Users;
