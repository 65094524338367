import React, { FC } from 'react';

import { Form, FormInstance, Input } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CommonDictionariesSlugs } from '@app/models/dictionaries';
import { CreatedSelect, GoogleAutocomplete } from '@components/AntFields';

import { IFormValues } from './interfaces';
import { ValidationSchema } from './validation';

interface IProps {
  initialValues?: Partial<IFormValues>;
  onFinish: (formValues: IFormValues) => void;
  recipientUid?: string;
  form: FormInstance;
}

const LABEL_COL = 6;
const WRAPPER_COL = 18;

export const RecipientShortForm: FC<IProps> = (props) => {
  const validationT = useTranslation(NSKeys.validation);
  const recipientsT = useTranslation(NSKeys.recipients);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  return (
    <Form
      form={props.form}
      name="catalog_form"
      id="catalogForm"
      initialValues={props.initialValues}
      onFinish={props.onFinish}
      autoComplete="off"
      labelCol={{ span: LABEL_COL }}
      wrapperCol={{ span: WRAPPER_COL }}>
      <Form.Item<IFormValues> name="name" required label={recipientsT.t('form.nameLabel')} rules={[yupSync]}>
        <Input placeholder={recipientsT.t('form.namePlaceholder')} />
      </Form.Item>
      <Form.Item<IFormValues> name="code" label={recipientsT.t('form.codeLabel')} rules={[yupSync]}>
        <Input placeholder={recipientsT.t('form.codePlaceholder')} />
      </Form.Item>
      <CreatedSelect
        removable
        labelCol={{ span: LABEL_COL }}
        wrapperCol={{ span: WRAPPER_COL }}
        required={false}
        rules={[yupSync]}
        name="retailer_uid"
        dictionaryName={CommonDictionariesSlugs.Retailers}
        label={recipientsT.t('form.retailerLabel')}
        placeholder={recipientsT.t('form.retailerPlaceholder')}
      />
      <CreatedSelect
        removable
        labelCol={{ span: LABEL_COL }}
        wrapperCol={{ span: WRAPPER_COL }}
        required={false}
        rules={[yupSync]}
        name="type_uid"
        dictionaryName={CommonDictionariesSlugs.RecipientTypes}
        label={recipientsT.t('form.typeLabel')}
        placeholder={recipientsT.t('form.typePlaceholder')}
      />
      <CreatedSelect
        removable
        mode="multiple"
        labelCol={{ span: LABEL_COL }}
        wrapperCol={{ span: WRAPPER_COL }}
        required={false}
        rules={yupSync}
        name="tags_uids"
        dictionaryName={CommonDictionariesSlugs.RecipientTags}
        label={recipientsT.t('form.tagsLabel')}
        placeholder={recipientsT.t('form.tagsPlaceholder')}
      />
      <GoogleAutocomplete required={true} form={props.form} rules={[yupSync]} hasError={false} defaultOpen={false} />
    </Form>
  );
};
