import { ColumnsType } from 'antd/es/table';

import { InvitationSchema } from '@app/models/users';
import { formatDateAndTime } from '@app/services/date';

export const baseColumns: ColumnsType<InvitationSchema> = [
  {
    title: 'tableColumns.email',
    dataIndex: 'email',
    key: 'email',
    ellipsis: true,
  },
  {
    title: 'tableColumns.expired_at',
    dataIndex: 'expired_at',
    key: 'expired_at',
    render: (_, row) => formatDateAndTime(row.expired_at),
    ellipsis: true,
  },
];
