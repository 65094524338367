import React, { FC, useState } from 'react';

import { EditTwoTone } from '@ant-design/icons';
import { theme as antTheme, Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { RetrieveUserSchema } from '@app/models/users';
import { useWindowSize } from '@app/services/hooks/useWindowSize';
import { TableEditMenu } from '@components/TableEditMenu';

import { useGetEmployeesQuery } from '../../../../hooks';

import { baseColumns } from './data/constants';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const TableList: FC = () => {
  const navigate = useNavigate();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const [columns, setColumns] = useState<ColumnsType<RetrieveUserSchema>>(baseColumns);
  const commonT = useTranslation(NSKeys.common);
  const employeesT = useTranslation(NSKeys.employees);
  const { innerHeight } = useWindowSize();
  const { token } = antTheme.useToken();
  const { data, isLoading } = useGetEmployeesQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
  });

  const handleTableHeaderChange = (checkedValues: any[]) => {
    setColumns(baseColumns.filter((item) => checkedValues.includes(item.key as string)));
  };

  return (
    <Container>
      <Table
        loading={isLoading}
        dataSource={data?.items}
        rowKey="pk"
        className="tableClassname"
        columns={[
          ...columns.map((el) => ({ ...el, title: employeesT.t(el.title as any) })),
          {
            title: (
              <TableEditMenu
                defaultValue={baseColumns.map((el) => el.key as string)}
                handleOnChange={handleTableHeaderChange}
                options={baseColumns.map((el) => ({
                  value: el.key as string,
                  label: employeesT.t(el.title || (el.key as any)),
                }))}
              />
            ),
            fixed: 'right',
            key: 'action',
            align: 'center',
            width: 40,
            render: (_, cell) =>
              cell.$permissions.update && (
                <EditTwoTone
                  twoToneColor={token.colorPrimary}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`edit/${cell.pk}`);
                  }}
                />
              ),
          },
        ]}
        pagination={{
          ...tableParams.pagination,
          total: data?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        rowClassName="cursorPointer"
        onRow={(data) => {
          return {
            onClick: () => navigate(`${data.pk}`),
          };
        }}
        onChange={(pagination) => {
          setTableParams({
            pagination,
          });
        }}
        scroll={{ y: innerHeight - 300, x: true }}
        style={{ overflow: 'auto' }}
      />
    </Container>
  );
};
