import React, { FC, useState } from 'react';

import { EditTwoTone } from '@ant-design/icons';

import { Layout, Row, Table, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';

import { TableParams } from '@app/models/interfaces';

import { useGetOrderImportsQuery } from '../../hooks';

import { BASE_COLUMNS_WIP } from './data/constants';

import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};
export const List: FC = () => {
  const navigate = useNavigate();
  const orderImportsT = useTranslation(NSKeys.orderImports);
  const commonT = useTranslation(NSKeys.common);
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const { data, isLoading } = useGetOrderImportsQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
  });
  const { token } = theme.useToken();

  return (
    <>
      <Layout.Header>
        <Row justify="space-between" align="middle">
          <Typography.Title level={2} className="title" style={{ marginBottom: 0 }}>
            {orderImportsT.t('title')}
          </Typography.Title>
        </Row>
      </Layout.Header>

      <Container>
        <Table
          loading={isLoading}
          dataSource={data?.items}
          rowKey="uid"
          className="tableClassname"
          style={{ marginTop: 24 }}
          scroll={{ x: true }}
          pagination={{
            ...tableParams.pagination,
            total: data?.total,
            showQuickJumper: true,
            showSizeChanger: true,
            position: ['bottomCenter'],
            showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
          }}
          onChange={(pagination) => {
            setTableParams({
              pagination,
            });
          }}
          columns={[
            ...BASE_COLUMNS_WIP.map((el) => ({ ...el, title: orderImportsT.t(el.title as any) })),
            {
              fixed: 'right',
              key: 'action',
              align: 'center',
              width: 40,
              render: (_, cell) => {
                return cell?.$permissions?.view ? (
                  <EditTwoTone
                    twoToneColor={token?.colorPrimary}
                    onClick={(e) => {
                      navigate(`/dashboard/orderImports/${cell.uid}`);
                    }}
                  />
                ) : null;
              },
            },
          ]}
        />
      </Container>
    </>
  );
};
