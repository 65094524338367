import React from 'react';

import { Form, Modal, Space, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { OrderImportDataSchema, OrderImportSchema } from '@app/models/orders';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';

import { RenderFieldByType } from '@components/RenderFieldByType';

import { useUpdateOrderImportData } from '../../../../hooks';

import { getInitialValues } from './data/getInitialValues';

interface IProps {
  open: boolean;
  activeRow: OrderImportDataSchema;
  orderImport: OrderImportSchema;
  setOpen: (value: boolean) => void;
}

export const RowEditModal: React.FC<IProps> = ({ open, setOpen, activeRow, orderImport }) => {
  const [form] = Form.useForm();
  const { token } = theme.useToken();
  const orderImportsT = useTranslation(NSKeys.orderImports);
  const commonT = useTranslation(NSKeys.common);

  const { mutate, isLoading, status } = useUpdateOrderImportData();

  React.useEffect(() => {
    const initialData = getInitialValues(activeRow, orderImport);
    form.setFieldsValue(initialData);
  }, [activeRow, orderImport]);

  React.useEffect(() => {
    if (status === 'success') {
      setOpen(false);
    }
  }, [status]);

  return (
    <Modal
      title={
        <Space align="center">
          <Typography.Title level={5} style={{ marginBottom: 0 }}>
            {orderImportsT.t('rowEditModalTitle')}
          </Typography.Title>
        </Space>
      }
      open={open}
      destroyOnClose
      onOk={() => form.submit()}
      okButtonProps={{
        loading: isLoading,
      }}
      cancelText={commonT.t('buttonCancel')}
      okText={commonT.t('buttonSave')}
      confirmLoading={false}
      onCancel={() => setOpen(false)}>
      <Form
        form={form}
        initialValues={getInitialValues(activeRow, orderImport)}
        onFinish={(data) => mutate({ data, order_import_data_uid: activeRow.uid })}
        name="email_invite"
        layout="vertical"
        style={{
          border: `1px solid ${token.colorBorder}`,
          margin: '20px -24px',
          padding: 25,
          borderLeft: 'none',
          borderRight: 'none',
        }}>
        {orderImport.fields.map((field, index) => (
          <RenderFieldByType
            key={index}
            disabled={false}
            type={field.field_type as any}
            form={form}
            required={field.is_required}
            label={field.name}
            name={[field.uid as any]}
          />
        ))}
      </Form>
    </Modal>
  );
};
