import React from 'react';

import { Flex, Layout, Spin } from 'antd';
import { useParams } from 'react-router-dom';

import { Breadcrumbs } from '@components/Breadcrumbs';

import { useGetProjectQuery } from '../../hooks';

import { ExportButton } from './components/ExportButton';

import { TableContainer } from './components/Table';

export const Single: React.FC = () => {
  const params = useParams<{ id: string }>();

  const { data, status } = useGetProjectQuery(params?.id || '');

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Flex justify="space-between" align="center" wrap style={{ minHeight: 64 }}>
          <Breadcrumbs apiTitles={[{ id: ':projectId', value: data?.title || params.id }]} />
          <Flex gap={8}>{data?.process?.uid && <ExportButton processUid={data.process.uid} />}</Flex>
        </Flex>
      </Layout.Header>
      {status === 'success' && data?.process?.uid && data?.uid ? (
        <TableContainer processId={data.process.uid} projectId={data.uid} />
      ) : (
        <Spin style={{ padding: 24 }} />
      )}
    </Layout>
  );
};
