import React, { FC } from 'react';

import { HolderOutlined } from '@ant-design/icons';
import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Button, Card, Divider, Flex, Space } from 'antd';

import { ProcessStageSchema, ProcessStageStatusSchema } from '@app/models/orders';

import { useStageStatusReorderQuery } from '../../../../../../hooks';

import { AddStageStatusModal } from '../../components/AddStageStatusModal';
import { StageText } from '../StageText';

import { StatusBlock } from '../StatusBlock';

import { StatusOptionsPopover } from './components/StatusOptionsPopover';

interface IProps extends ProcessStageSchema {
  process_uid: string;
}

export const CardRender: FC<IProps> = ({
  process_uid,
  uid,
  name,
  statuses: initialStatuses,
  is_editable,
  is_removable,
}) => {
  const [statuses, setStatuses] = React.useState<ProcessStageStatusSchema[]>([]);
  const { attributes, isDragging, listeners, setNodeRef, transform, transition } = useSortable({ id: uid });
  const { mutate, status } = useStageStatusReorderQuery();

  React.useEffect(() => {
    setStatuses(initialStatuses);
  }, [initialStatuses]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;
    const newFieldIndex = statuses.findIndex((status) => status.uid === over?.id);

    if (over && active.id !== over?.id) {
      mutate({ stageUid: uid, data: { uid: active.id.toString(), position: newFieldIndex + 1 } });
      setStatuses((prevState) => {
        const oldIndex = prevState.findIndex((status) => status.uid === active.id);
        const newIndex = prevState.findIndex((status) => status.uid === over.id);

        return arrayMove(statuses, oldIndex, newIndex);
      });
    }
  };

  const style = {
    transition,
    transform: CSS.Translate.toString(transform),
    opacity: isDragging ? '0.6' : '1',
    zIndex: 100,
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  return (
    <Card ref={setNodeRef} key={uid} styles={{ body: { padding: '12px 24px' } }} style={style}>
      <Flex vertical gap={4} justify="start" align="baseline">
        <Flex style={{ width: '100%' }} justify="space-between">
          <Space>
            {is_editable && (
              <Button
                type="text"
                icon={<HolderOutlined style={{ opacity: 0.6 }} />}
                {...attributes}
                {...listeners}
                style={{ cursor: 'grab' }}
              />
            )}
            <StageText isEditable={is_editable} name={name} stage_uid={uid} />
          </Space>
          {is_removable && (
            <Flex justify="flex-end">
              <StatusOptionsPopover process_uid={process_uid} stage_uid={uid} />
            </Flex>
          )}
        </Flex>
        <Divider plain style={{ margin: '4px' }} />
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
          modifiers={[restrictToVerticalAxis]}>
          <SortableContext items={statuses.map((el) => el.uid) || []} strategy={verticalListSortingStrategy}>
            {statuses.map((status) => (
              <StatusBlock
                stageUid={uid}
                name={status.name}
                statusUid={status.uid}
                color={status.color}
                key={status.uid}
                isEditable={status.is_editable}
                isRemovable={status.is_removable}
              />
            ))}
          </SortableContext>
        </DndContext>
      </Flex>

      {is_editable && <AddStageStatusModal stageUid={uid} total={statuses.length} />}
    </Card>
  );
};
