import { AxiosError } from 'axios';
import { FieldData } from 'rc-field-form/es/interface';

import { Namespace, TFunction } from 'react-i18next';

import { IErrorResponse } from '@app/models/error';

interface IError {
  code: string;
  codeMessage: string;
  fields: { name: string; variants?: string[] }[];
}

const findPath = (pointer: string) => (path: string) => {
  const pathCropped = path.replace('.', '/').replace(/^/, '/');

  return pathCropped === pointer;
};

export const getFormErrorsFromResponse = (
  formValues: any,
  err: any,
  t: TFunction<Namespace<'validation'>>,
  skipSearch = false
): { pointerErrors: FieldData[]; formMessages: string[] } => {
  const errors = err as AxiosError<IErrorResponse>;
  const formMessages: string[] = [];
  let pointerErrors: FieldData[] = [];
  const formKeys = Object.keys(formValues);
  if (errors.response?.data?.errors) {
    if (skipSearch) {
      pointerErrors = errors.response?.data?.errors.reduce((acc, err) => {
        acc.push({ name: err?.source?.pointer, errors: [t(err.code)] });
        return acc;
      }, [] as FieldData[]);
    } else {
      pointerErrors = errors.response?.data?.errors.reduce((acc, err) => {
        const formKey = formKeys.find(findPath(err?.source?.pointer || ''));
        if (formKey) {
          acc.push({ name: formKey, errors: [t(err.code)] });
        } else {
          formMessages.push(t(err.code));
        }
        return acc;
      }, [] as FieldData[]);
    }
  }

  return { pointerErrors, formMessages };
};

export const getFormErrorsForAlert = (err: any, t: TFunction<Namespace<'validation'>>): IError[] => {
  const errors = err as AxiosError<IErrorResponse>;

  if (errors?.response?.data?.errors) {
    return Object.values(
      errors.response?.data?.errors.reduce((acc, item) => {
        const code = item.code || 'bad_intake';
        if (!acc[code]) {
          acc[code] = {
            code: code,
            codeMessage: t(code, item.title),
            fields: [],
          };
        }

        acc[code].fields.push({
          name: item?.meta?.cell || item?.meta?.field_name,
          variants: item?.meta?.variants?.map((el) => el.attr_name) || [],
        });
        return acc;
      }, {} as any)
    );
  }

  return [];
};
