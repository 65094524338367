import * as process from 'process';

import React from 'react';

import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { theme as antTheme, Divider, Drawer, Flex, Skeleton, Spin, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ProcessStageSchema } from '@app/models/orders';
import { AddModuleModal } from '@pages/Processes/components/SettingsPopover/components/ProcessSettings/components/AddModuleModal';

import { useGetProcessQuery, useProcessStageReorderQuery } from '../../../../hooks';

import { AddStageModal } from './components/AddStageModal';
import { CardRender } from './components/CardRender';
import { ModuleRender } from './components/ModuleRender';
import { Title } from './components/Title';

interface IProcessSettingsProps {
  open: boolean;
  onClose: () => void;
}

export const ProcessSettings: React.FC<IProcessSettingsProps> = ({ open, onClose }) => {
  const { token } = antTheme.useToken();
  const [stages, setStages] = React.useState<ProcessStageSchema[]>([]);
  const [initialStage, setInitialStages] = React.useState<ProcessStageSchema[]>([]);
  const ordersT = useTranslation(NSKeys.orders);
  const params = useParams<{ processId: string }>();
  const { data: processData, status: loadingStatus } = useGetProcessQuery(params.processId);
  const { mutate, status } = useProcessStageReorderQuery();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  React.useEffect(() => {
    if (loadingStatus === 'success' && processData) {
      setStages(processData.stages);
      setInitialStages(processData.stages);
    }
    if (status === 'error' && initialStage.length) {
      setStages(initialStage);
    }
  }, [loadingStatus, processData, initialStage]);

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    const newIndex = stages.findIndex((item) => item.uid === over?.id);

    if (over && active.id !== over.id && typeof newIndex === 'number' && params.processId && over.id) {
      // mutate({ uid: params.processId, data: { uid: params.processId, position: newIndex + 1 } });
      mutate({ uid: params.processId, data: { uid: active.id.toString(), position: newIndex + 1 } });
      setStages((stages) => {
        const oldIndex = stages.findIndex((item) => item.uid === active.id);
        const newIndex = stages.findIndex((item) => item.uid === over.id);

        return arrayMove(stages, oldIndex, newIndex);
      });
    }
  };

  return (
    <Drawer
      destroyOnClose
      title={
        <Flex vertical justify={'start'} style={{ marginLeft: 16 }}>
          {processData?.uid && processData.name ? (
            <Title process_uid={processData?.uid} name={processData?.name} />
          ) : (
            <Skeleton.Input active />
          )}
          <Typography.Text type="secondary" style={{ margin: 0 }}>
            {ordersT.t('processSettings')}
          </Typography.Text>
        </Flex>
      }
      placement="right"
      size="large"
      onClose={onClose}
      styles={{ body: { backgroundColor: token.colorBgLayout } }}
      open={open}>
      {loadingStatus === 'loading' ? (
        <Flex justify="center" align="center" style={{ minHeight: 300 }}>
          <Spin />
        </Flex>
      ) : (
        <Flex vertical={true}>
          <Divider orientation="left">{ordersT.t('processSettingsModulesDivider')}</Divider>
          <Flex vertical gap={8}>
            {processData?.modules?.map((module) => (
              <ModuleRender
                key={module.uid}
                uid={module.uid || ''}
                name={module.kind}
                is_removable={module.is_removable}
                process_uid={params.processId || ''}
              />
            ))}
          </Flex>
          {params.processId && <AddModuleModal process_uid={params.processId} />}
          <Divider orientation="left">{ordersT.t('processSettingsStagesDivider')}</Divider>
          <DndContext
            sensors={sensors}
            collisionDetection={closestCenter}
            onDragEnd={handleDragEnd}
            modifiers={[restrictToVerticalAxis]}>
            <Flex vertical gap={16}>
              <SortableContext items={stages?.map((el) => el.uid) || []} strategy={verticalListSortingStrategy}>
                {stages.map((stage) => (
                  <CardRender key={stage.uid} {...stage} process_uid={params.processId || ''} />
                ))}
              </SortableContext>
              {params.processId && <AddStageModal total={stages?.length || 0} process_uid={params.processId} />}
            </Flex>
          </DndContext>
        </Flex>
      )}
    </Drawer>
  );
};
