import React from 'react';

import { SplitCellsOutlined, TableOutlined } from '@ant-design/icons';

import { Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import {  useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';

import { ORBIS_ORDERS_LIST_VIEW } from '@app/services/appSettings';

import { ViewType } from '../../data/interfaces';

export const ViewSwitch: React.FC = () => {
  const ordersT = useTranslation(NSKeys.orders);
  const [listView, setListView] = useLocalStorage(ORBIS_ORDERS_LIST_VIEW, ViewType.LIST);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setSearchParams({ view: listView });
  }, []);

  // React.useEffect(() => {
  //   console.log(location);
  //   // setSearchParams({ view: listView });
  // }, [location]);

  const handleChange = (view: ViewType) => {
    setListView(view);
    setSearchParams({ view: view });
  };

  return (
    <Radio.Group
      defaultValue={listView}
      size="middle"
      buttonStyle="solid"
      style={{ display: 'flex', flexWrap: 'nowrap' }}
      onChange={(e) => handleChange(e.target.value)}>
      <Tooltip title={ordersT.t('switchViewList')}>
        <Radio.Button value={ViewType.LIST}>
          <TableOutlined />
        </Radio.Button>
      </Tooltip>
      <Tooltip title={ordersT.t('switchViewBoard')}>
        <Radio.Button value={ViewType.BOARD}>
          <SplitCellsOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );
};
