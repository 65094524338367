import { useInfiniteQuery } from '@tanstack/react-query';

import { IProjectsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

interface IParams extends IProjectsParams {
  enabled: boolean;
}

export const useGetProjectsQuery = ({ limit, process_uid, enabled }: IParams) => {
  return useInfiniteQuery({
    queryKey: ['getCatalogs'],
    queryFn: async ({ pageParam = 0 }) => {
      return await Api.orders.getProjects({ offset: pageParam, limit, process_uid });
    },
    getNextPageParam: (current, allPages) => {
      const count = allPages.length * (limit || 1);
      return count >= (current.total || 0) ? undefined : count;
    },
    enabled: enabled,
  });
};
