import React from 'react';

import { Button, Flex, Form, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounceCallback } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';

import { ProjectSelect } from '@components/AntFields';

import { useMoveToProjectQuery } from './hooks/useMoveToProjectQuery';

import { Container, Label } from './styles';

export interface IFormValues {
  projectUid?: string;
}

interface IProps {
  process_uid: string;
  order_uid: string;
  project_uid?: string;
  project_label?: string;
  showLabel?: boolean;
  permissionMoveToProject: boolean;
}

export const ChangeProject: React.FC<IProps> = ({
  process_uid,
  order_uid,
  project_label,
  project_uid,
  showLabel = true,
  permissionMoveToProject = false,
}) => {
  const [open, setOpen] = React.useState(false);
  const [label, setLabel] = React.useState(project_label);
  const projectsT = useTranslation(NSKeys.projects);
  const { mutate, status } = useMoveToProjectQuery();

  const [form] = Form.useForm<IFormValues>();
  const fieldValue = Form.useWatch<IFormValues>('projectUid', form);

  React.useEffect(() => {
    form.setFieldValue('projectUid', project_uid || null);
    setLabel(project_label);
  }, [project_uid, project_label]);

  const handleSubmit = (values: IFormValues) => {
    if (!project_uid && !values.projectUid) return;

    mutate({ order_uid, data: { project: values.projectUid ? { uid: values.projectUid } : null } });
  };

  const debouncedChange = useDebounceCallback((values?: IFormValues) => {
    handleSubmit(values as IFormValues);
  }, 300);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Form<IFormValues>
        style={{ display: 'inline-flex', alignItems: 'center' }}
        form={form}
        onFinish={handleSubmit}
        name="project_select"
        onValuesChange={(_, values) => {
          debouncedChange(values);
        }}
        layout="vertical">
        <Container
          placement="bottomLeft"
          arrow={false}
          destroyTooltipOnHide={true}
          content={
            <Form.Item<IFormValues> name="projectUid" style={{ minWidth: '150px', margin: 0 }}>
              <ProjectSelect
                process_uid={process_uid}
                // allowClear
                dropdownStyle={{ position: 'fixed' }}
                placeholder={projectsT.t('filters.project')}
                onChange={(_, option: any) => {
                  setLabel(option?.label);
                  setOpen(false);
                }}
              />
            </Form.Item>
          }
          trigger="click"
          open={open && permissionMoveToProject}
          onOpenChange={setOpen}>
          <Button shape="round" disabled={status === 'loading' || !permissionMoveToProject}>
            <Form.Item<IFormValues> name="projectUid" style={{ display: 'none' }}>
              <ProjectSelect placeholder="" disabled={!permissionMoveToProject} />
            </Form.Item>
            <Row align="middle" justify="start">
              {showLabel && <Label $hasValue={!!fieldValue || !label}>Проект</Label>}

              {label && label !== projectsT.t('filters.nonProject') ? (
                <Flex>
                  <Typography.Text ellipsis={{ tooltip: label }} style={{ marginLeft: 5 }}>
                    {label}
                  </Typography.Text>
                </Flex>
              ) : (
                <Typography.Text style={{ marginLeft: 5, marginRight: 10, color: '#00000073' }}>
                  {projectsT.t('filters.nonProject')}
                </Typography.Text>
              )}
            </Row>
          </Button>
        </Container>
      </Form>
    </div>
  );
};
