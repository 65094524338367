import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetEmployeesQuery = ({ limit, offset }: { offset: number; limit: number }) => {
  return useQuery({
    queryKey: ['getUsers', limit, offset],
    queryFn: () => Api.users.getUsers({ limit, offset }),
  });
};
