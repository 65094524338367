import React from 'react';

import { Select, SelectProps } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { useGetProjectsQuery } from './hooks/useGetProjectsQuery';

interface IProps extends SelectProps {
  placeholder: string;
  process_uid?: string;
}

export const ProjectSelect: React.FC<IProps> = ({ placeholder, process_uid, ...props }) => {
  const { data, hasNextPage, isFetching, fetchNextPage } = useGetProjectsQuery({
    limit: 10,
    process_uid: process_uid,
    enabled: !props.disabled,
  });
  const projectsT = useTranslation(NSKeys.projects);

  const dataSource =
    data?.pages
      ?.map((data) => data.items || [])
      .flat()
      ?.map((el) => ({ data: el, label: el.title, value: el.uid })) || [];

  return (
    <Select
      placeholder={placeholder}
      options={[{ label: projectsT.t('filters.nonProject'), value: null }, ...dataSource]}
      defaultOpen
      loading={isFetching}
      dropdownStyle={{ position: 'fixed' }}
      onPopupScroll={(e: any) => {
        const { target } = e;
        if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
          fetchNextPage();
        }
      }}
      notFoundContent={null}
      filterOption={false}
      {...props}
    />
  );
};
