import React, { FC, Suspense } from 'react';

import {
  AppstoreOutlined,
  BarChartOutlined,
  BuildOutlined,
  FolderOpenOutlined,
  IdcardOutlined,
  ImportOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  ProfileOutlined,
  SnippetsOutlined,
  ToolOutlined,
  TruckOutlined,
} from '@ant-design/icons';
import { Button, Image, Layout, Menu, Space, Spin, theme } from 'antd';
import i18n from 'i18next';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { setupDayJs } from '@app/services/date';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { stringToBoolean } from '@app/services/stringToBoolean';

import { UserMenu } from './components/UserMenu';

import { Container, LogoContainer, SpinnerContainer } from './styles';

enum MenuKeys {
  PRODUCTS = '/dashboard/products',
  PROCESSES = '/dashboard/processes',
  CATALOGS = '/dashboard/catalogs',
  EMPLOYEES = '/dashboard/employees',
  CONFIRMATION_SUB = 'confirmation',
  GROUPS = '/dashboard/groups',
  CHAINS = '/dashboard/orderApprovalChains',
  LISTS_SUB = 'lists',
  RECIPIENTS = '/dashboard/recipients',
  CONTACTS = '/dashboard/contacts',
  WAREHOUSES = '/dashboard/warehouses',
  ANALYTICS = '/dashboard/analytics',
  ORDER_IMPORTS = '/dashboard/orderImports',
  PROJECTS = '/dashboard/projects',
}

export const DashboardLayout: FC = () => {
  const navigate = useNavigate();
  const selectedKey = useLocation().pathname;
  const [collapsed, setCollapsed] = React.useState(false);
  const [activeRoute, setActiveRoute] = React.useState(selectedKey);
  const [defaultOpenKeys, setDefaultOpenKeys] = React.useState<MenuKeys[]>([]);
  const { t } = useTranslation(NSKeys.common);
  const { token } = theme.useToken();
  const currentSession = useLocalSelector((state) => state.currentSession);

  React.useEffect(() => {
    i18n.changeLanguage(currentSession.lang.toLowerCase());
    setupDayJs(currentSession.lang.toLowerCase());
  }, [currentSession.lang]);

  React.useEffect(() => {
    const [empty, url1, url2] = selectedKey.split('/');
    const res = '/' + [url1, url2].join('/');

    if ([MenuKeys.GROUPS, MenuKeys.CHAINS].some((key) => key === res)) {
      setDefaultOpenKeys([MenuKeys.CONFIRMATION_SUB]);
    }
    setActiveRoute(res);
  }, [selectedKey]);

  const handleIconClick = () => navigate('/');

  const USER_NAME =
    currentSession.data?.first_name || currentSession.data?.last_name
      ? `${currentSession.data?.first_name} ${currentSession.data?.last_name}`
      : '';

  return (
    <Container
      $colorBgBase={token.colorBgBase}
      $colorBgLayout={token.colorBgLayout}
      $colorPrimary={token.colorPrimary}
      $boxShadow={token.boxShadowSecondary}
      $colorBorder={token.colorBorderSecondary}>
      <Helmet>
        <title>{currentSession?.data?.scope.title}</title>
        <link rel="icon" type="image/png" href={currentSession?.data?.scope?.favicon?.variants?.small?.url || ''} />
      </Helmet>
      <Layout>
        <Layout.Sider width={209} collapsed={collapsed} onCollapse={setCollapsed}>
          <div>
            <LogoContainer>
              <Image
                key={currentSession?.data?.scope?.logo?.uid}
                src={
                  collapsed
                    ? currentSession?.data?.scope?.favicon?.variants?.big?.url
                    : currentSession?.data?.scope?.logo?.variants?.small?.url
                }
                height={35}
                onClick={handleIconClick}
                preview={false}
                style={{ margin: '20px 0', cursor: 'pointer', width: 'auto' }}
              />
            </LogoContainer>

            <UserMenu collapsed={collapsed} userName={USER_NAME} />
            <Menu
              mode="inline"
              onSelect={(item) => navigate(item.key)}
              defaultActiveFirst={true}
              selectedKeys={[activeRoute]}
              key={defaultOpenKeys.length}
              defaultOpenKeys={defaultOpenKeys}
              items={[
                {
                  label: t('menuTitleProducts'),
                  icon: <ToolOutlined />,
                  key: MenuKeys.PRODUCTS,
                  disabled: !currentSession?.productsContext?.scope?.$permissions?.view_products,
                },
                {
                  label: t('menuTitleOrders'),
                  icon: <SnippetsOutlined />,
                  key: MenuKeys.PROCESSES,
                  disabled: !currentSession?.ordersContext?.scope?.$permissions?.view_orders,
                },
                {
                  label: t('menuCatalogs'),
                  icon: <AppstoreOutlined />,
                  key: MenuKeys.CATALOGS,
                  disabled: !currentSession?.productsContext?.scope?.$permissions?.view_catalogs,
                },
                {
                  label: t('menuCatalogEmployees'),
                  icon: <IdcardOutlined />,
                  key: MenuKeys.EMPLOYEES,
                  disabled: !currentSession?.data?.scope?.$permissions?.view_users,
                },
                {
                  label: t('menuTitleConfirmation'),
                  icon: <BuildOutlined />,
                  key: MenuKeys.CONFIRMATION_SUB,
                  className: 'multiple',
                  disabled:
                    !currentSession?.data?.scope?.$permissions?.view_user_groups &&
                    !currentSession?.data?.scope?.$permissions?.view_order_approval_chains,
                  children: [
                    {
                      label: t('menuTitleGroups'),
                      key: MenuKeys.GROUPS,
                      disabled: !currentSession?.data?.scope?.$permissions?.view_user_groups,
                    },
                    {
                      label: t('menuTitleChains'),
                      key: MenuKeys.CHAINS,
                      disabled: !currentSession?.data?.scope?.$permissions?.view_order_approval_chains,
                    },
                  ],
                },
                {
                  label: t('menuTitleLists'),
                  icon: <ProfileOutlined />,
                  key: MenuKeys.LISTS_SUB,
                  className: 'multiple',
                  disabled:
                    !currentSession?.dictionariesContext?.scope?.$permissions?.view_recipients &&
                    !currentSession?.dictionariesContext?.scope?.$permissions?.view_contacts,
                  children: [
                    {
                      label: t('menuTitleRecipients'),
                      key: MenuKeys.RECIPIENTS,
                      disabled: !currentSession?.dictionariesContext?.scope?.$permissions?.view_recipients,
                    },
                    {
                      label: t('menuTitleContacts'),
                      key: MenuKeys.CONTACTS,
                      disabled: !currentSession?.dictionariesContext?.scope?.$permissions?.view_contacts,
                    },
                  ],
                },
                {
                  label: t('menuWarehouses'),
                  icon: <TruckOutlined />,
                  key: MenuKeys.WAREHOUSES,
                  disabled:
                    !currentSession?.warehousesContext?.scope?.$permissions?.view_warehouses &&
                    !stringToBoolean(process.env.REACT_APP_FEATURE_WAREHOUSES),
                },
                {
                  label: t('menuAnalytics'),
                  icon: <BarChartOutlined />,
                  key: MenuKeys.ANALYTICS,
                  disabled: !stringToBoolean(process.env.REACT_APP_FEATURE_ANALYTICS),
                },
                {
                  label: t('menuOrderImports'),
                  icon: <ImportOutlined />,
                  key: MenuKeys.ORDER_IMPORTS,
                  disabled: !currentSession?.ordersContext?.scope?.$permissions?.view_import_orders,
                },
                {
                  label: t('menuProjects'),
                  icon: <FolderOpenOutlined />,
                  key: MenuKeys.PROJECTS,
                  disabled: !currentSession?.ordersContext?.scope?.$permissions?.view_projects,
                },
              ]}
            />
          </div>

          <Button
            style={{ marginLeft: 20, marginTop: 20 }}
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
          />
        </Layout.Sider>
        <Layout className="site-layout" style={{ marginLeft: collapsed ? 80 : 209 }}>
          <Suspense
            fallback={
              <SpinnerContainer>
                <Spin />
              </SpinnerContainer>
            }>
            <Outlet />
          </Suspense>
        </Layout>
      </Layout>
    </Container>
  );
};
