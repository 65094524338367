import { ColumnsType } from 'antd/es/table';

import { OrderImportSchema } from '@app/models/orders';
import { formatDateAndTime } from '@app/services/date';

import { DownloadButton } from '../../../components/DownloadButton';

export const BASE_COLUMNS_WIP: ColumnsType<OrderImportSchema> = [
  {
    title: 'tableColumns.dt_created',
    dataIndex: 'dt_created',
    key: 'dt_created',
    ellipsis: true,
    render: (el) => formatDateAndTime(el),
  },
  {
    title: 'tableColumns.author',
    dataIndex: 'author',
    key: 'author',
    render: (_, row) => row.author.name,
    ellipsis: true,
  },
  {
    title: 'tableColumns.catalog',
    dataIndex: 'catalog',
    key: 'catalog',
    render: (_, row) => row.catalog.name,
    ellipsis: true,
  },
  {
    title: 'tableColumns.project',
    dataIndex: 'project',
    key: 'project',
    render: (_, row) => row?.project?.title || '',
    ellipsis: true,
  },
  {
    title: 'tableColumns.uploaded_file',
    dataIndex: 'uploaded_file',
    key: 'uploaded_file',
    render: (_, row) =>
      row?.$permissions?.download_file ? (
        <DownloadButton upload_uid={row?.uploaded_file?.uid} uid={row.uid} fileName={row.uploaded_file.filename} />
      ) : null,
    ellipsis: true,
  },
  {
    title: 'tableColumns.total',
    dataIndex: 'orders_total',
    key: 'orders_total',
    align: 'center',
    render: (_, row) => `${row.orders_imported} / ${row.orders_total}`,
    ellipsis: true,
  },
];
