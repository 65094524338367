import React from 'react';

import { DownloadOutlined } from '@ant-design/icons';
import { Button, theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';

import { getHostName } from '@app/services/httpClient';

import { useExportQuery } from '../../../../hooks';
import { useProjectDataParams } from '../../../../providers';

interface IProps {
  processUid?: string;
}

export const ExportButton: React.FC<IProps> = ({ processUid }) => {
  const { token } = theme.useToken();
  const params = useParams<{ id: string }>();
  const ordersT = useTranslation(NSKeys.orders);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const { queryParams } = useProjectDataParams();

  const { mutate, status, data } = useExportQuery();

  React.useEffect(() => {
    if (status === 'success' && data?.filename) {
      const link = document.createElement('a');
      link.download = `orders-${new Date().getUTCDate()}`;
      link.href = `${getHostName()}/orders/exported/${data?.filename}`;
      link.click();
    }
  }, [data, status]);

  if (!currentSession.ordersContext?.scope?.$permissions?.export_orders || !params.id) {
    return null;
  }

  return (
    <Tooltip placement="bottomLeft" title={ordersT.t('exportTooltip')}>
      <Button
        icon={<DownloadOutlined style={{ color: token.colorPrimary }} />}
        loading={status === 'loading'}
        onClick={() => {
          let query = `project.uid=="${params.id}"`;
          if (queryParams.query) {
            query = `${query};${queryParams.query}`;
          }
          mutate({ uid: processUid || '', ...(queryParams.sort && { sort: queryParams.sort }), query });
        }}
      />
    </Tooltip>
  );
};
