import React, { FC, useState } from 'react';

import { Table } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { useWindowSize } from '@app/services/hooks/useWindowSize';

import { useGetEmployeeInvitesQuery } from '../../../../hooks';

import { TableActionMenu } from './components/TableActionMenu';

import { baseColumns } from './data/constants';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};

export const InvitesTable: FC = () => {
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const commonT = useTranslation(NSKeys.common);
  const employeesT = useTranslation(NSKeys.employees);
  const { innerHeight } = useWindowSize();
  const { data, isLoading } = useGetEmployeeInvitesQuery({
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
  });

  return (
    <Container>
      <Table
        loading={isLoading}
        dataSource={data?.items}
        rowKey="pk"
        className="tableClassname"
        columns={[
          ...baseColumns.map((el) => ({ ...el, title: employeesT.t(el.title as any) })),
          {
            key: 'action',
            width: 60,
            fixed: 'right',
            onCell: () => {
              return {
                onClick: (e) => console.log(e.stopPropagation()),
              };
            },
            render: (_, cell) => <TableActionMenu invitation_uuid={cell.uuid} />,
          },
        ]}
        pagination={{
          ...tableParams.pagination,
          total: data?.total,
          showQuickJumper: true,
          showSizeChanger: true,
          position: ['bottomCenter'],
          showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
        }}
        rowClassName="cursorPointer"
        onChange={(pagination) => {
          setTableParams({
            pagination,
          });
        }}
        scroll={{ y: innerHeight - 300, x: true }}
        style={{ overflow: 'auto' }}
      />
    </Container>
  );
};
