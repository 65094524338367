import React, { createContext, ReactNode, useContext, useState } from 'react';

import { OrderItemSchema } from '@app/models/orders';

interface IContext {
  onAddItem: (item: OrderItemSchema) => void;
  onDeleteItems: (uids: string[]) => void;
  setArtifactUid: (uids: string) => void;
  onCountChange: (count: number, uid: string) => void;
  // setData: (order: RetrieveOrderSchema | undefined) => void;
  // data: RetrieveOrderSchema | undefined;
  items: any[];
  setItems: (data: any[]) => void;
}

const ProjectOrderItemsContext = createContext<IContext>(null!);

function ProjectOrderItemsProvider({ children }: { children: ReactNode }) {
  const [artifactUid, setArtifactUid] = useState('');
  // const [data, setData] = useState<RetrieveOrderSchema | undefined>(undefined);
  const [items, setItems] = useState<any[]>([]);

  // React.useEffect(() => {
  //   console.log(items);
  // }, [items]);

  const handleAddItem = (newItem: OrderItemSchema) => {
    setItems((prevState) => [...prevState, newItem]);
  };

  const handleDeleteItem = (uids: string[]) => {
    setItems((prevState) => [...(prevState?.filter((item) => !uids.includes(item.uid)) || [])]);
  };

  const handleCountChange = (count: number, uid: string) => {
    if (count === 0) {
      return handleDeleteItem([uid]);
    }

    setItems((prevState) => [
      ...(prevState?.map((item) => (item.uid === uid ? { ...item, quantity: count } : item)) || []),
    ]);
  };

  const value = {
    // data,
    // setData,
    items,
    setItems,
    setArtifactUid,
    onAddItem: handleAddItem,
    onDeleteItems: handleDeleteItem,
    onCountChange: handleCountChange,
  };
  return <ProjectOrderItemsContext.Provider value={value}>{children}</ProjectOrderItemsContext.Provider>;
}

const useProjectOrderItems = () => {
  const context = useContext(ProjectOrderItemsContext);

  if (context === undefined) {
    throw new Error('useProviderState must be used within a OrderItemsProvider');
  }

  return context;
};

export { ProjectOrderItemsContext, ProjectOrderItemsProvider, useProjectOrderItems };
