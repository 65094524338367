import React, { FC, useEffect, useState } from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { ProgressButton } from '@components/ProgressButton';
import { useCreateOrders, useGetImportState } from '@pages/OrderImports/hooks';
interface IProps {
  uid: string;
}

export const PollingButton: FC<IProps> = ({ uid }) => {
  const client = useQueryClient();
  const { data: createData, mutate: createOrders, isLoading: isCreatingLoading } = useCreateOrders();
  const {
    data: importStateData,
    mutate: getImportState,
    isLoading: isGetImportStateLoading,
    isError,
  } = useGetImportState();
  const [isPolling, setIsPolling] = useState(false);
  const [isFinished, setIsFinished] = useState(true);
  const [total, setTotal] = useState<undefined | number>();
  const [processed, setProcessed] = useState<undefined | number>();

  const orderImportsT = useTranslation(NSKeys.orderImports);
  const commonT = useTranslation(NSKeys.common);

  useEffect(() => {
    if (!createData?.uid || isGetImportStateLoading || isError || isFinished) return;

    setIsPolling(true);
    const interval = setInterval(() => {
      getImportState(createData.uid);
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [createData, isGetImportStateLoading, isError, isFinished]);

  const updateList = async () => {
    await client.invalidateQueries(['getOrderImportData']);
    message.open({
      type: 'success',
      content: commonT.t('dataAddSuccess'),
    });
  };

  useEffect(() => {
    if (importStateData) {
      setTotal(importStateData.orders_total || 0);
      setProcessed(importStateData.orders_processed || 0);
    }
    if (importStateData?.status === 'finished') {
      setIsPolling(false);
      setIsFinished(true);
      updateList();
    }

    if (importStateData?.status === 'failed') {
      setIsPolling(false);
      setIsFinished(true);
      message.error(commonT.t('error400'));
    }
  }, [importStateData]);

  return (
    <ProgressButton
      type="primary"
      size="middle"
      onClick={() => {
        setIsFinished(false);
        setIsPolling(true);
        createOrders({ order_import_uid: uid });
      }}
      total={total}
      processed={processed}
      title={orderImportsT.t('createOrdersButton')}
      loading={isPolling || isCreatingLoading}
    />
  );
};
