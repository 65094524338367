import React, { useState } from 'react';

import { MailOutlined, PlusOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Form, message, Modal, Row, Select, SelectProps, Space, theme, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import Api from '@app/services/api';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

const options: SelectProps['options'] = [];

export const InviteModal: React.FC = () => {
  const client = useQueryClient();
  const employeesT = useTranslation(NSKeys.employees);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [form] = Form.useForm<IFormValues>();
  const email = Form.useWatch('email', form);
  const { token } = theme.useToken();

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: IFormValues) => {
    if (values.email.length) {
      setLoading(true);
      Api.users
        .postInvitations(values.email.map((el) => ({ email: el })))
        .then((res) => {
          client.invalidateQueries(['getEmployeeInvites']);
          if (res.length) {
            const errors = res.filter((el) => el.error);
            if (errors.length) {
              message.warning(
                <Space direction="vertical">
                  {res.map((el) => (
                    <Row key={el.email}>
                      <Typography.Text strong>{el.email}</Typography.Text>:{' '}
                      {el.error ? commonT.t(el.error as any) : employeesT.t('inviteSuccess')}
                    </Row>
                  ))}
                </Space>,
                10
              );
            } else {
              message.success(employeesT.t('inviteSuccess'));
            }
          }
        })
        .catch(() => message.error(commonT.t('error400')))
        .finally(() => {
          setLoading(false);
          setOpen(false);
          form.resetFields();
        });
    }
  };

  return (
    <>
      <Button type="primary" icon={<PlusOutlined />} onClick={() => setOpen(true)} size="middle">
        {employeesT.t('addButton')}
      </Button>
      <Modal
        title={
          <Space align="center">
            <Typography.Title level={5} style={{ marginBottom: 0 }}>
              {employeesT.t('inviteModalTitle')}
            </Typography.Title>
            <Typography.Text type="secondary">{email?.length}</Typography.Text>
          </Space>
        }
        open={open}
        destroyOnClose
        onOk={() => form.submit()}
        okButtonProps={{
          icon: <MailOutlined />,
          loading: loading,
        }}
        okText={employeesT.t('form.addSubmitBtn')}
        confirmLoading={confirmLoading}
        onCancel={() => setOpen(false)}>
        <Form
          form={form}
          onFinish={handleSubmit}
          name="email_invite"
          layout="vertical"
          style={{
            border: `1px solid ${token.colorBorder}`,
            margin: '20px -24px',
            padding: 25,
            borderLeft: 'none',
            borderRight: 'none',
          }}>
          <Form.Item
            name="email"
            label={<Typography.Text type="secondary">{employeesT.t('form.emailLabel')}</Typography.Text>}
            rules={[yupSync]}>
            <Select mode="tags" style={{ width: '100%' }} tokenSeparators={[',']} options={options} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
