import React from 'react';

import { Flex, Progress } from 'antd';

import { ColumnsType } from 'antd/es/table';

import { SystemSlugs } from '@app/models/dictionaries';
import { RetrieveProjectSchema } from '@app/models/orders';
import { DictionaryPostfix } from '@components/DictionaryPostfix';

const getPercentage = (value?: number): number => Math.round((value || 0) * 10000) / 100;

export const getBaseColumns = (bgColor: string): ColumnsType<RetrieveProjectSchema> => [
  {
    title: 'tableColumns.title',
    dataIndex: 'title',
    key: 'title',
    ellipsis: true,
  },
  {
    title: 'tableColumns.process',
    dataIndex: 'process',
    key: 'process',
    ellipsis: true,
    render: (_, record) => record.process.name,
  },
  {
    title: 'tableColumns.total',
    dataIndex: 'total',
    key: 'total',
    align: 'center',
    ellipsis: true,
    render: (_, record) =>
      `${(record?.metrics?.orders?.is_completed__count || 0) + (record?.metrics?.orders?.is_cancelled__count || 0)} / ${
        record.metrics.orders?.count || 0
      }`,
  },
  {
    title: 'tableColumns.percent',
    dataIndex: 'percent',
    key: 'percent',
    align: 'center',
    ellipsis: true,
    render: (_, record) => (
      <Progress
        style={{ minWidth: '150px' }}
        size={['100%', 15]}
        percent={getPercentage(record?.metrics?.orders?.percent_complete)}
        strokeColor={bgColor}
      />
    ),
  },
  {
    title: 'tableColumns.service_level',
    dataIndex: 'service_level',
    key: 'service_level',
    align: 'center',
    ellipsis: true,
    render: (_, record) => (
      <Progress
        style={{ minWidth: '150px' }}
        size={['100%', 15]}
        percent={getPercentage(record?.metrics?.orders?.percent_service_level)}
        strokeColor={bgColor}
      />
    ),
  },
  {
    title: 'tableColumns.sum_material',
    dataIndex: 'sum_material',
    key: 'sum_material',
    align: 'center',
    ellipsis: true,
    render: (_, record) => (
      <Flex gap={4} justify="center">
        <span>{record?.metrics?.items?.price__sum || 0}</span>
        <DictionaryPostfix dictionaryType={SystemSlugs.Currencies} />
      </Flex>
    ),
  },
];
