import { useQuery } from '@tanstack/react-query';

import { IOrderByProjectParams } from '@app/models/interfaces';

import Api from '@app/services/api';

export const useGetOrdersByProjectQuery = ({ project_uid, offset, limit, query, sort }: IOrderByProjectParams) => {
  return useQuery({
    queryKey: ['getOrdersByProject', project_uid, limit, offset, query, sort],
    queryFn: () => Api.orders.getOrdersByProject({ project_uid, offset, limit, query, sort }),
    enabled: !!project_uid,
  });
};
