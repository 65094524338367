import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IParams {
  project_uid: string;
}

export const useRestoreProjectQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ project_uid }: IParams) => {
      await Api.orders.postRestoreProject(project_uid);
    },
    onSuccess: async (_) => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
      await client.invalidateQueries(['getProjects']);
    },
    onError: async () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
      await client.invalidateQueries(['getProjects']);
    },
  });
};
