import React from 'react';

import { Form, Input, Modal, Space, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { ProcessSelect } from '@components/AntFields';

import { useGetProjectQuery, useUpdateProjectQuery } from '../../../../hooks';

import { ValidationSchema } from './data/validation';
import { IFormValues } from './interfaces';

interface IProps {
  projectUid?: string;
  onClose: () => void;
  open: boolean;
}

export const UpdateProject: React.FC<IProps> = ({ projectUid, open, onClose }) => {
  const projectsT = useTranslation(NSKeys.projects);
  const validationT = useTranslation(NSKeys.validation);
  const commonT = useTranslation(NSKeys.common);
  const [form] = Form.useForm<IFormValues>();
  const email = Form.useWatch('email', form);
  const { isLoading, mutate, status } = useUpdateProjectQuery();
  const { data: projectData, isLoading: isProjectLoading } = useGetProjectQuery(open ? projectUid || '' : '');

  React.useEffect(() => {
    if (projectData && projectUid) {
      form.setFieldsValue({ title: projectData.title, processUid: projectData.process.uid });
    }
  }, [projectData]);

  React.useEffect(() => {
    if (status === 'success') {
      form.resetFields();
      onClose();
    }
  }, [status]);

  const yupSync = {
    async validator({ field }: any, value: any) {
      await ValidationSchema(validationT.t).validateSyncAt(field, { [field]: value });
    },
  };

  const handleSubmit = async (values: IFormValues) => {
    if (projectUid) {
      mutate({ project_uid: projectUid, data: { title: values.title } });
    }
  };

  return (
    <Modal
      title={
        <Space align="center">
          <Typography.Text type="secondary">{email?.length}</Typography.Text>
        </Space>
      }
      open={open}
      destroyOnClose
      onOk={() => form.submit()}
      okButtonProps={{
        loading: isLoading || isProjectLoading,
      }}
      okText={commonT.t('buttonSave')}
      confirmLoading={isLoading || isProjectLoading}
      onCancel={onClose}>
      <Form form={form} onFinish={handleSubmit} name="project_create" layout="vertical">
        <Form.Item<IFormValues>
          name="title"
          required
          label={<Typography.Text type="secondary">{projectsT.t('form.title')}</Typography.Text>}
          rules={[yupSync]}>
          <Input />
        </Form.Item>
        <Form.Item<IFormValues>
          name="processUid"
          required
          label={<Typography.Text type="secondary">{projectsT.t('form.process')}</Typography.Text>}
          rules={[yupSync]}>
          <ProcessSelect disabled placeholder={projectsT.t('form.process')} />
        </Form.Item>
      </Form>
    </Modal>
  );
};
