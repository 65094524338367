import React from 'react';

import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveProjectSchema } from '@app/models/orders';
import { DropDownMenuButton } from '@components/DropDownMenuButton';

import { useArchiveProjectQuery, useRestoreProjectQuery } from '../../../../hooks';
import { UpdateProject } from '../UpdateProject';

interface IProps {
  cell: RetrieveProjectSchema;
}

export const TableActionMenu: React.FC<IProps> = ({ cell }) => {
  const projectsT = useTranslation(NSKeys.projects);
  const [editOpen, setEditOpen] = React.useState(false);
  const { isLoading: isArchiving, mutate: archive } = useArchiveProjectQuery();
  const { isLoading: isRestoring, mutate: restore } = useRestoreProjectQuery();

  return (
    <React.Fragment>
      <DropDownMenuButton
        type={'link'}
        menu={[
          {
            label: <Typography.Text>{projectsT.t('editButton')}</Typography.Text>,
            key: 'UpdateProject',
            onClick: () => setEditOpen(true),
          },
          ...(cell?.is_archived
            ? [
                {
                  label: <Typography.Text>{projectsT.t('restoreButton')}</Typography.Text>,
                  key: 'UnArchiveProject',
                  onClick: () => !isRestoring && restore({ project_uid: cell.uid }),
                },
              ]
            : [
                {
                  label: <Typography.Text>{projectsT.t('archiveButton')}</Typography.Text>,
                  key: 'ArchiveProject',
                  onClick: () => !isArchiving && archive({ project_uid: cell.uid }),
                },
              ]),
        ]}
        placement="bottomRight"
      />

      <UpdateProject projectUid={cell?.uid} onClose={() => setEditOpen(false)} open={editOpen} />
    </React.Fragment>
  );
};
