import React, { FC } from 'react';

import { Button, Form, Layout, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { FormRecipient, IFormValues } from '../../components/Form';
import { useGetRecipient, useUpdateRecipientQuery } from '../../hooks';
import { useRecipientContacts } from '../../providers';

export const Edit: FC = () => {
  const commonT = useTranslation(NSKeys.common);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const goBack = () => navigate('/dashboard/recipients');
  const params = useParams<{ id: string }>();
  const { setData: setContacts, data: contacts } = useRecipientContacts();
  const { data: currentSession } = useLocalSelector((state) => state.currentSession);
  const { mutate: updateRecipient, isLoading: isMutating, status: mutateStatus } = useUpdateRecipientQuery();
  const { data: recipient, isLoading: isRecipientLoading } = useGetRecipient(params?.id || '');

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    if (params.id) {
      updateRecipient({
        uid: params.id,
        data: {
          name: formValues.name,
          code: formValues.code || null,
          retailer_uid: formValues.retailer_uid || null,
          type_uid: formValues.type_uid || null,
          tags_uids: formValues.tags_uids || [],
          contacts: contacts?.map((el) => ({ uid: el.uid })),
          address: {
            provider: formValues.provider as any,
            provider_address_uid: formValues.provider_address_uid as any,
            street_name: formValues.street_name,
            street_number: formValues?.street_number,
            place_name: formValues?.place_name || null,
          },
        },
      });
    }
  };

  React.useEffect(() => {
    if (mutateStatus === 'success') {
      form.resetFields();
      navigate(`/dashboard/recipients/${params.id}`);
    }
  }, [mutateStatus]);

  React.useEffect(() => {
    if (recipient?.contacts?.length) {
      setContacts(recipient.contacts);
    }
  }, [recipient]);

  return (
    <>
      <Layout.Header>
        <Breadcrumbs apiTitles={[{ id: ':recipientId', value: recipient?.name }]} />
        <Row justify="space-between" align="middle">
          <HeaderTitle loading={isRecipientLoading} title={`${recipient?.name || params.id}`} onClick={goBack} />
          <Space size="middle">
            <Button onClick={goBack} size="middle" name="catalog_form" id="catalog_form">
              {commonT.t('buttonCancel')}
            </Button>
            <Button disabled={isMutating} type="primary" size="middle" form="catalogForm" htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      {!isRecipientLoading && (
        <FormRecipient
          form={form}
          recipientUid={params.id}
          onFinish={handleSubmit}
          initialValues={{
            name: recipient?.name,
            code: recipient?.code || '',
            retailer_uid: recipient?.retailer?.uid,
            type_uid: recipient?.type?.uid,
            tags_uids: recipient?.tags?.map((el) => el.uid),
            // autoComplete: recipient?.address?.as_string,
            provider: currentSession?.scope?.settings?.address_provider,
            provider_address_uid: recipient?.address?.provider_address_uid,
            street_name: recipient?.address?.street_name,
            street_number: recipient?.address?.street_number || '',
            place_name: recipient?.address?.place_name || '',
          }}
        />
      )}
    </>
  );
};
