import React, { FC } from 'react';

import { Card, Flex, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { OptionsPopover } from './components/OptionsPopover';

type IProps = {
  uid: string;
  process_uid: string;
  name: string;
  is_removable: boolean;
};

export const ModuleRender: FC<IProps> = ({ uid, name, process_uid, is_removable }) => {
  const ordersT = useTranslation(NSKeys.orders);

  return (
    <Card key={uid} styles={{ body: { padding: '12px 24px' } }}>
      <Flex style={{ width: '100%' }} justify="space-between">
        <Space>{ordersT.t(`fieldsForm.kind.${name}` as any)}</Space>
        {is_removable && <OptionsPopover module_uid={uid} process_uid={process_uid} />}
      </Flex>
    </Card>
  );
};
