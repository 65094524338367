import React from 'react';

import { Flex, Skeleton, Typography } from 'antd';

import { useUpdateStageQuery } from '../../../../../../hooks';

interface IProps {
  name: string;
  stage_uid: string;
  isEditable?: boolean;
}

export const StageText: React.FC<IProps> = ({ name, stage_uid, isEditable = true }) => {
  const [editableStr, setEditableStr] = React.useState(name);

  React.useEffect(() => {
    setEditableStr(name);
  }, [name]);

  const { mutate, status } = useUpdateStageQuery();

  const onEdit = (value: string) => {
    if (name !== value) {
      mutate({ name: value, stage_uid });
    }
  };

  return (
    <Flex style={{ marginBottom: 0, width: '100%' }} align="center">
      {status === 'loading' ? (
        <Skeleton.Input active />
      ) : (
        <Typography.Text
          type="secondary"
          editable={isEditable && { onChange: onEdit }}
          style={{ width: '100%', marginTop: 0 }}>
          {editableStr}
        </Typography.Text>
      )}
    </Flex>
  );
};
