import { useMutation, useQueryClient } from '@tanstack/react-query';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import Api from '@app/services/api';

interface IParams {
  process_uid: string;
  module_uid: string;
}

export const useDeleteModuleQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async ({ module_uid, process_uid }: IParams) => {
      await Api.orders.deleteModule(process_uid, { module: { uid: module_uid } });
    },
    onSuccess: async (_) => {
      message.open({
        type: 'success',
        content: commonT.t('dataPatchSuccess'),
      });
      await client.invalidateQueries(['getProcess']);
    },
    onError: async (e: any) => {
      message.error(commonT.t('error400'));
      await client.invalidateQueries(['getProcess']);
    },
  });
};
