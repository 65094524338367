import { Flex, Typography } from 'antd';
import dayjs from 'dayjs';
import { Namespace, TFunction } from 'react-i18next';

import {
  EOperations,
  FilterCatalog,
  FilterContact,
  FilterDate,
  FilterInput,
  FilterRecipient,
  FilterUser,
  IField,
  IInputFilterValue,
  OrderStatusSelect,
  ProjectSelect,
} from '@app/components/FilterBlock';

export const getFilters = (
  columns: any[],
  t: TFunction<Namespace<'common'>>,
  processUid: string,
  excludedFilters?: string[]
): IField[] => {
  const getRenderValue = (value: IInputFilterValue) => {
    const valueParsed = Array.isArray(value.value) ? value.value.map((el) => el.label).join(', ') : value.value;
    return value.operation ? (
      <Flex gap={5}>
        <Typography.Text type="secondary">
          {t(`filters.${value.operation?.toLowerCase()}` as any, { defaultValue: value.operation })}
        </Typography.Text>
        <Typography.Text ellipsis style={{ maxWidth: 200 }}>
          {valueParsed}
        </Typography.Text>
      </Flex>
    ) : (
      <Typography.Text ellipsis style={{ maxWidth: 200 }}>
        {valueParsed}
      </Typography.Text>
    );
  };

  const filterByExcluded = (column: any) => {
    if (column?.key && excludedFilters?.length) {
      return !excludedFilters?.some((excluded) => excluded === column?.key);
    }
    return true;
  };

  return columns
    ?.filter(filterByExcluded)
    ?.map((column: any) => {
      if (column.fieldType === 'user') {
        return {
          label: column?.title,
          name: `${column?.dataIndex}.id`,
          inputField: (
            <FilterUser
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'contact') {
        return {
          label: column?.title,
          name: `${column?.dataIndex}.uid`,
          inputField: (
            <FilterContact
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'recipient') {
        return {
          label: column?.title,
          name: `${column?.dataIndex}.uid`,
          inputField: (
            <FilterRecipient
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'catalog') {
        return {
          label: column?.title,
          name: `${column?.key_for_filter}`,
          inputField: (
            <FilterCatalog
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'string' || column.fieldType === 'text') {
        return {
          label: column?.title,
          name: column?.dataIndex,
          inputField: (
            <FilterInput
              label={column?.title}
              operations={[EOperations.EQ, EOperations.CONT, EOperations.NEQ, EOperations.EX, EOperations.NEX]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'num') {
        return {
          label: column?.title,
          name: column?.dataIndex,
          inputField: (
            <FilterInput
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.GE,
                EOperations.LE,
                EOperations.LT,
                EOperations.GT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'date') {
        return {
          label: column?.title,
          name: column?.dataIndex,
          inputField: (
            <FilterDate
              format="YYYY-MM-DD"
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.GE,
                EOperations.LE,
                EOperations.LT,
                EOperations.GT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (column.fieldType === 'datetime') {
        return {
          label: column?.title,
          name: column?.dataIndex,
          inputField: (
            <FilterDate
              showTime
              keepIso
              format="YYYY-MM-DD HH:MM"
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.GE,
                EOperations.LE,
                EOperations.LT,
                EOperations.GT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: (value: IInputFilterValue) => {
            const date = value.value ? dayjs(value.value).format('YYYY-MM-DD HH:MM') : '';
            return value.operation ? (
              <Flex gap={5}>
                <Typography.Text type="secondary">
                  {t(`filters.${value.operation?.toLowerCase()}` as any, { defaultValue: value.operation })}
                </Typography.Text>
                <Typography.Text>{date}</Typography.Text>
              </Flex>
            ) : (
              <Typography.Text>{date}</Typography.Text>
            );
          },
        };
      }

      if (!column.fieldType && column.key === 'current_status_uid') {
        return {
          label: column?.title,
          name: 'current_status_uid',
          inputField: (
            <OrderStatusSelect
              processUid={processUid}
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      if (!column.fieldType && column.key === 'project.title') {
        return {
          label: column?.title,
          name: 'project.uid',
          inputField: (
            <ProjectSelect
              processUid={processUid}
              label={column?.title}
              operations={[
                EOperations.EQ,
                EOperations.NEQ,
                EOperations.IN,
                EOperations.OUT,
                EOperations.EX,
                EOperations.NEX,
              ]}
            />
          ),
          labelInValue: true,
          renderDisplayValue: getRenderValue,
        };
      }

      return undefined as any;
    })
    ?.filter((el) => el);
};
