import { FC, useMemo } from 'react';

import { theme as antTheme, Button, ButtonProps, Progress } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

interface IProps extends ButtonProps {
  title: string;
  loading: boolean;
  total?: number;
  processed?: number;
}

export const ProgressButton: FC<IProps> = ({ loading, title, processed, total, ...buttonProps }) => {
  const { token } = antTheme.useToken();
  const commonT = useTranslation(NSKeys.common);

  const isPolling = useMemo(
    () => Number.isInteger(total) && Number.isInteger(processed) && loading,
    [total, processed, loading]
  );

  const renderProgress = () =>
    isPolling ? (
      <Progress
        strokeColor={token.colorBgLayout}
        strokeWidth={9}
        type="circle"
        percent={Math.round(((processed || 0) / (total || 0)) * 100)}
        size={18}
      />
    ) : null;

  return (
    <Button type="primary" loading={loading && { icon: renderProgress() }} {...buttonProps}>
      {isPolling ? `${commonT.t('dataFetching')} ${processed} ${commonT.t('paginationOf')} ${total}` : title}
    </Button>
  );
};
