import React from 'react';

import { Form, message, Modal } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { RetrieveRecipientSchema } from '@app/models/dictionaries';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { IFormValues, RecipientShortForm } from '@components/recipients';

import { useCreateRecipientQuery } from '../hooks/useCreateRecipientQuery';

interface IProps {
  initialValue: string;
  isModalOpen: boolean;
  onAdd: (values: RetrieveRecipientSchema) => void;
  onCancel: () => void;
}

export const Create: React.FC<IProps> = (props) => {
  const breadcrumbsT = useTranslation(NSKeys.breadcrumbs);
  const commonT = useTranslation(NSKeys.common);
  const validationT = useTranslation(NSKeys.validation);
  const [form] = Form.useForm<IFormValues>();
  const { mutate: createRecipient, status, data, error } = useCreateRecipientQuery();
  const { data: currentSession } = useLocalSelector((state) => state.currentSession);

  React.useEffect(() => {
    if (props.initialValue) {
      form.setFieldValue('name', props.initialValue);
    }
  }, []);

  React.useEffect(() => {
    if (status === 'success') {
      props.onAdd(data);
      form.resetFields();
    }
    if (status === 'error' && error) {
      const formValues = form.getFieldsValue();
      const { pointerErrors, formMessages } = getFormErrorsFromResponse(formValues, error, validationT.t);
      // console.log(formValues, errors);
      if (pointerErrors.length) {
        form.setFields(pointerErrors);
      }
      message.open({
        type: 'error',
        content: formMessages.length ? formMessages.join(',') : commonT.t('error400'),
      });
    }
  }, [status, data, error]);

  const handleCancel = () => {
    form.resetFields();
    props.onCancel();
  };

  const handleSubmit = (formValues: IFormValues): void => {
    createRecipient({
      ...formValues,
      code: formValues.code || null,
      retailer_uid: formValues.retailer_uid || null,
      type_uid: formValues.type_uid || null,
      tags_uids: formValues.tags_uids || [],
      contacts: [],
      address: {
        provider_address_uid: formValues.provider_address_uid,
        provider: (currentSession?.scope?.settings?.address_provider || '') as any,
        street_name: formValues.street_name,
        street_number: formValues.street_number,
        place_name: formValues.place_name || null,
      },
    });
  };

  return (
    <Modal
      title={breadcrumbsT.t('recipientsAdd')}
      open={props.isModalOpen}
      centered
      destroyOnClose={true}
      width="75%"
      style={{ maxWidth: '900px' }}
      cancelText={commonT.t('cancel')}
      okButtonProps={{ form: 'catalogForm', htmlType: 'submit' }}
      okText={commonT.t('create')}
      onCancel={handleCancel}>
      <RecipientShortForm form={form} initialValues={{ name: props.initialValue }} onFinish={handleSubmit} />
    </Modal>
  );
};
