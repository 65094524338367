import React, { FC, useEffect, useState } from 'react';

import { message } from 'antd';

import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { ProgressButton } from '@components/ProgressButton';
import { useGetUploadState } from '@pages/Catalogs/hooks';

interface IProps {
  uid?: string;
  isLoading: boolean;
}

export const PollingButton: FC<IProps> = ({ uid, isLoading }) => {
  const navigate = useNavigate();
  const { mutate, data, isLoading: isPollingStateLoading, isError } = useGetUploadState();
  const [isPolling, setIsPolling] = useState(false);
  const [total, setTotal] = useState<undefined | number>();
  const [processed, setProcessed] = useState<undefined | number>();

  const commonT = useTranslation(NSKeys.common);
  const orderImportsT = useTranslation(NSKeys.orderImports);

  useEffect(() => {
    if (!uid) return;
    mutate(uid);
  }, [uid]);

  useEffect(() => {
    if (!uid || isPollingStateLoading || isError || data?.status === 'failed') return;

    setIsPolling(true);
    const interval = setInterval(() => {
      mutate(uid); // Вызываем мутацию каждые 1000 мс
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [uid, isPollingStateLoading, isError, data]);

  useEffect(() => {
    if (data) {
      setTotal(data.orders_total || 0);
      setProcessed(data.orders_processed || 0);
    }
    if (data?.status === 'finished' && data?.order_import_uid) {
      setIsPolling(false);
      navigate(`/dashboard/orderImports/${data?.order_import_uid}`);
    }
    if (data?.status === 'failed' && data?.order_import_uid) {
      setIsPolling(false);
      message.error(commonT.t('error400'));
    }
  }, [data]);

  return (
    <div style={{ display: 'block' }}>
      <ProgressButton
        type="primary"
        htmlType="submit"
        size="middle"
        total={total}
        processed={processed}
        title={orderImportsT.t('runButton')}
        loading={isLoading || isPolling}
      />
    </div>
  );
};
