import React from 'react';

import { Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { DropDownMenuButton } from '@components/DropDownMenuButton';

import { useDeleteInvitationQuery } from '../../../../../../hooks';

interface IProps {
  invitation_uuid: string;
}

export const TableActionMenu: React.FC<IProps> = ({ invitation_uuid }) => {
  const employeesT = useTranslation(NSKeys.employees);
  const { isLoading, mutate } = useDeleteInvitationQuery();

  return (
    <DropDownMenuButton
      type={'link'}
      menu={[
        {
          label: <Typography.Text>{employeesT.t('deleteButton')}</Typography.Text>,
          key: 'ArchiveProject',
          onClick: () => !isLoading && mutate(invitation_uuid),
        },
      ]}
      placement="bottomRight"
    />
  );
};
