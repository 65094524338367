import React, { FC, useState } from 'react';

import { theme as antTheme, Layout, Row, Space } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { useLocalSelector } from '@app/services/hooks/useRedux';
import { HeaderTitle } from '@components/HeaderTitle';
import { Tabs as StyledTabs } from '@pages/SpaceSettings/styles';

import { InviteModal } from './components/InviteModal';
import { InvitesTable } from './components/InvitesTable';
import { TableList } from './components/ListTable';

export enum TABS {
  ALL = 'all',
  INVITE = 'invite',
}

export const EmployeesList: FC = () => {
  const [activeTab, setActiveTab] = useState(TABS.ALL);
  const currentSession = useLocalSelector((state) => state.currentSession);
  const employeesT = useTranslation(NSKeys.employees);
  const { token } = antTheme.useToken();

  return (
    <Layout>
      <Layout.Header style={{ border: 0 }}>
        <Row justify="space-between" align="middle">
          <HeaderTitle title={employeesT.t('title')} />
          {currentSession?.data?.scope?.$permissions?.invite_users && (
            <Space size="middle">
              <InviteModal />
            </Space>
          )}
        </Row>
      </Layout.Header>

      <Layout.Content>
        <StyledTabs
          destroyInactiveTabPane={true}
          defaultActiveKey={TABS.ALL}
          items={[
            {
              key: TABS.ALL,
              label: employeesT.t('allTab'),
              children: <TableList />,
            },
            {
              key: TABS.INVITE,
              label: employeesT.t('inviteTab'),
              children: <InvitesTable />,
            },
          ]}
          onChange={(e) => {
            setActiveTab(e as TABS);
          }}
          $colorBgBase={token.colorBgBase}
        />
      </Layout.Content>
    </Layout>
  );
};
