import React, { FC } from 'react';

import { Alert, Button, Divider, Flex, Form, FormInstance, Table, theme, Typography } from 'antd';

import { useTranslation } from 'react-i18next';

import { useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { MappingSettingsDTO, PreviewDTO } from '@app/models/orders';
import { getFormErrorsForAlert } from '@app/services/getFormErrorsFromResponse';
import { usePostOrderImportRun } from '@pages/Catalogs/hooks';

import { IFormValues, IRunFormValues } from '../../interfaces';

import { PollingButton } from './components/PollingButton';

import { Summary } from './components/Summary';
import { getColumns } from './data/getColumns';
import { getOptions } from './data/getOptions';

interface IProps {
  settingsData?: MappingSettingsDTO;
  previewData?: PreviewDTO;
  form: FormInstance;
  previewFormValues: IFormValues;
  projectUid?: string;
}

export const Tables: FC<IProps> = ({ settingsData, previewFormValues, form, previewData, projectUid }) => {
  const params = useParams<{ id: string }>();
  const validationT = useTranslation(NSKeys.validation);
  const orderImportsT = useTranslation(NSKeys.orderImports);
  // const themeToken = theme.useToken();
  const options = getOptions(settingsData);
  const commonT = useTranslation(NSKeys.common);

  const { mutate: postRun, data: importRunData, isLoading, error: runError } = usePostOrderImportRun();

  const handleRun = async (values: IRunFormValues) => {
    if (params.id) {
      postRun({
        ...(projectUid && { project_uid: projectUid }),
        uploaded_file_uid: previewFormValues.uploaded_file_uid,
        catalog_uid: params.id,
        layout: {
          direction: previewFormValues.layout.direction,
          orders_region: { ...previewFormValues.layout.orders_region, mapping: values.ordersMapping },
          items_region: { ...previewFormValues.layout.items_region, mapping: values.itemsMapping },
        },
      });
    }
  };

  const parsedErrors = React.useMemo(() => getFormErrorsForAlert(runError, validationT.t), [runError]);

  return (
    <Flex vertical gap={28}>
      <>
        {runError && (
          <Alert
            description={
              parsedErrors.length ? (
                <Flex vertical gap={10}>
                  {parsedErrors.map((error) => (
                    <Flex key={error.code} gap={8}>
                      <Typography.Text type="secondary">
                        {error.codeMessage}
                        {error.fields?.[0]?.name ? ':' : ''}{' '}
                      </Typography.Text>
                      {error.fields.map((field, index) => (
                        <Flex gap={4} key={index}>
                          <Typography.Text>{field.name}</Typography.Text>
                          {field.variants?.length === 1 ? (
                            <span>({field.variants?.join(', ')});</span>
                          ) : !field.variants?.length ? (
                            ''
                          ) : (
                            <span>(требуется одно из: {field.variants?.join(', ')});</span>
                          )}
                        </Flex>
                      ))}
                    </Flex>
                  ))}
                </Flex>
              ) : (
                commonT.t('error400')
              )
            }
            type="error"
            closable
          />
        )}
        <Form<IRunFormValues> form={form} onFinish={handleRun}>
          <Flex vertical gap={15}>
            <>
              <Divider orientation="left" plain>
                {orderImportsT.t('previewTableItemsTitle')}
              </Divider>
              <Table
                className="hiddenThead"
                size="small"
                columns={getColumns(previewData?.items)}
                scroll={{ x: 'auto' }}
                dataSource={previewData?.items}
                loading={isLoading}
                pagination={false}
                summary={(pageData) => <Summary prefix="itemsMapping" data={pageData[0]} options={options.items} />}
              />
              <Divider orientation="left" plain>
                {orderImportsT.t('previewTableOrdersTitle')}
              </Divider>
              <Table
                className="hiddenThead"
                size="small"
                columns={getColumns(previewData?.orders)}
                scroll={{ x: 'auto' }}
                dataSource={previewData?.orders}
                loading={isLoading}
                pagination={false}
                summary={(pageData) => <Summary prefix="ordersMapping" data={pageData[0]} options={options.orders} />}
              />
            </>
            <PollingButton isLoading={isLoading} uid={importRunData?.uid} />
          </Flex>
        </Form>
      </>
    </Flex>
  );
};
