import React from 'react';

import { Form, Input, Row } from 'antd';

interface IProps {
  searchValue: string;
  placeholder: string;
  onSearchChange: (value: string) => void;
  onSearchClick: () => void;
  isLoading: boolean;
}

export const SearchControls: React.FC<IProps> = (props) => (
  <div
    style={{
      display: 'inline-flex',
      width: '100%',
      justifyContent: 'flex-end',
      flexDirection: 'column',
      alignItems: 'end',
    }}>
    <Form.Item>
      <Input.Search
        size="middle"
        value={props.searchValue}
        onChange={(e) => props.onSearchChange(e.target.value)}
        placeholder={props.placeholder}
        enterButton
        disabled={props.isLoading}
        onSearch={props.onSearchClick}
      />
    </Form.Item>
  </div>
);
