import React from 'react';

import { FileAddOutlined, FileZipOutlined } from '@ant-design/icons';

import { Radio, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useLocalStorage } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';

import { ORBIS_PROJECTS_LIST_TYPE } from '@app/services/appSettings';

import { ListType } from '../../interfaces';

export const ListTypeSwitch: React.FC = () => {
  const projectsT = useTranslation(NSKeys.projects);
  const [listView, setListView] = useLocalStorage(ORBIS_PROJECTS_LIST_TYPE, ListType.ACTIVE);
  const [searchParams, setSearchParams] = useSearchParams();

  React.useEffect(() => {
    setSearchParams({ view: listView });
  }, []);

  const handleChange = (view: ListType) => {
    setListView(view);
    setSearchParams({ view: view });
  };

  return (
    <Radio.Group
      defaultValue={listView}
      size="middle"
      buttonStyle="solid"
      style={{ display: 'flex', flexWrap: 'nowrap' }}
      onChange={(e) => handleChange(e.target.value)}>
      <Tooltip title={projectsT.t('switchListTypeActive')}>
        <Radio.Button value={ListType.ACTIVE}>
          <FileAddOutlined />
        </Radio.Button>
      </Tooltip>
      <Tooltip title={projectsT.t('switchListTypeArchive')}>
        <Radio.Button value={ListType.ARCHIVED}>
          <FileZipOutlined />
        </Radio.Button>
      </Tooltip>
    </Radio.Group>
  );
};
