import { Form, Input, Radio, Select, Space } from 'antd';
import type { ColumnsType } from 'antd/es/table';

import { Namespace, TFunction } from 'react-i18next';

import {
  ArtifactAccessPolicySchema,
  FieldGroupAccessPolicySchema,
  MoveToStatusAccessPolicySchema,
  OrderAccessPolicySchema,
  RetrieveProcessSchema,
} from '@app/models/orders';

type DataSource =
  | ({
      type: 'artifact';
    } & ArtifactAccessPolicySchema)
  | ({
      type: 'field_group';
    } & FieldGroupAccessPolicySchema)
  | ({
      type: 'move_to_status';
    } & MoveToStatusAccessPolicySchema)
  | ({
      type: 'order';
    } & OrderAccessPolicySchema);

export const getColumns = ({
  settingsData,
  processData,
  t,
}: {
  settingsData: DataSource[];
  processData: RetrieveProcessSchema;
  t: TFunction<Namespace<'orders'>>;
}): ColumnsType<DataSource> => {
  console.log(settingsData, processData.stages);

  const stages = processData.stages?.map((el) => ({ title: el.name, uid: el.uid }));
  const actionColumns: ColumnsType<DataSource> =
    stages?.map((stage, stageIndex) => ({
      title: stage.title,
      render: (el, record, rowIndex) => {
        const accessKey = stage.uid;

        return (
          <div>
            {/* {JSON.stringify(['policies', rowIndex, 'access', accessKey, rowIndex])} */}
            {record.type === 'field_group' && (
              <Form.Item name={['policies', rowIndex, 'field_group', 'uid']} style={{ display: 'none' }}>
                <Input />
              </Form.Item>
            )}
            {record.type === 'artifact' && (
              <Form.Item name={['policies', rowIndex, 'artifact', 'uid']} style={{ display: 'none' }}>
                <Input />
              </Form.Item>
            )}
            <Form.Item name={['policies', rowIndex, 'type']} style={{ display: 'none' }}>
              <Input />
            </Form.Item>
            <Form.Item noStyle name={['policies', rowIndex, 'access', accessKey]}>
              {/* <Radio.Group> */}
              {/* <Space direction="vertical" align="start"> */}
              {/*   {record.available_levels?.map((action) => ( */}
              {/*     <Radio value={action} key={action} style={{ textAlign: 'left' }}> */}
              {/*       <>{t(`accessSettingsActins.${action}` as any, action)}</> */}
              {/*     </Radio> */}
              {/*   ))} */}
              {/*   {record?.access?.[stage.uid]} */}
              {/* </Space> */}

              <Select
                style={{ width: 125 }}
                options={
                  record.available_levels?.map((action) => ({
                    value: action,
                    label: t(`accessSettingsActins.${action}` as any, action),
                  })) || []
                }
              />
              {/* </Radio.Group> */}
            </Form.Item>
          </div>
        );
      },
    })) || [];

  return [
    {
      title: '',
      dataIndex: 'scope',
      render: (el, record) => {
        const value =
          record.type === 'field_group'
            ? record.field_group?.name || record?.field_group?.alias || record?.field_group?.uid
            : record.type === 'artifact'
            ? record.artifact?.type || record.artifact?.uid
            : record.type;
        return <>{t(`accessSettingsRows.${value}` as any, value)}</>;
      },
    },
    ...actionColumns,
  ];
};
