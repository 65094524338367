import { Namespace, TFunction } from 'react-i18next';
import * as Yup from 'yup';

import { IFormValues } from './interfaces';

export const ValidationSchema = (t: TFunction<Namespace<'validation'>>): Yup.SchemaOf<IFormValues> =>
  Yup.object().shape({
    name: Yup.string().required(t('required')),
    code: Yup.string().nullable(),
    retailer_uid: Yup.string(),
    type_uid: Yup.string(),
    tags_uids: Yup.array(),
    autoComplete: Yup.string(),

    provider_address_uid: Yup.string().required(t('required')),
    provider: Yup.string().required(t('required')),

    country: Yup.string(),
    city: Yup.string(),
    region: Yup.string(),
    postal_code: Yup.string(),

    street_name: Yup.string().required(t('required')),
    street_number: Yup.string().required(t('required')),
    place_name: Yup.string(),
  });
