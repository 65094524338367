import React, { useState } from 'react';

import { Flex, Layout, Row, Spin, Table, theme, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { TableParams } from '@app/models/interfaces';
import { OrderImportDataSchema } from '@app/models/orders';
import { formatDateAndTime } from '@app/services/date';

import { Breadcrumbs } from '@components/Breadcrumbs';
import { HeaderTitle } from '@components/HeaderTitle';

import { DownloadButton } from '../../components/DownloadButton';
import { useGetOrderImport, useGetOrderImportData } from '../../hooks';

import { PollingButton } from './components/PollingButton';
import { RowEditModal } from './components/RowEditModal';
import { getColumns } from './data/getColumns';
import { Container } from './styles';

const INITIAL_TABLE_PARAMS = {
  pagination: {
    current: 1,
    pageSize: 10,
  },
};
export const Single: React.FC = () => {
  const navigate = useNavigate();
  const orderImportsT = useTranslation(NSKeys.orderImports);
  const commonT = useTranslation(NSKeys.common);
  const [activeRow, setActiveRow] = useState<OrderImportDataSchema | null>(null);
  const [editOpen, setEditOpen] = useState(false);
  const params = useParams<{ id: string }>();
  const [tableParams, setTableParams] = useState<TableParams>(INITIAL_TABLE_PARAMS);
  const { token } = theme.useToken();

  const { data: orderImport, isLoading: idOrderImportLoading } = useGetOrderImport(params.id || '');
  const { data: orderImportData, isLoading: isorderImportDataLoading } = useGetOrderImportData({
    order_import_uid: params.id || '',
    offset:
      ((tableParams.pagination?.current || INITIAL_TABLE_PARAMS.pagination.current) - 1) *
      (tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize),
    limit: tableParams.pagination?.pageSize || INITIAL_TABLE_PARAMS.pagination.pageSize,
  });

  const goBack = () => navigate('/dashboard/orderImports');
  const title = orderImport
    ? `${orderImport.catalog.name} (${formatDateAndTime(orderImport?.dt_created)})`
    : params.id || '';

  const onEditClick = (row: OrderImportDataSchema) => {
    setActiveRow(row);
    setEditOpen(true);
  };

  const onOrderClick = (row: OrderImportDataSchema) => {
    navigate(`/dashboard/processes/${orderImport?.process?.uid}/orders/${row.order_uid}`);
  };

  return (
    <Layout>
      <Layout.Header style={{ borderBottom: 'none' }}>
        <Breadcrumbs apiTitles={[{ id: ':orderImportsUid', value: title }]} />
        <Row justify="space-between" align="middle" style={{ minHeight: 64 }}>
          <HeaderTitle loading={idOrderImportLoading} title={title} onClick={goBack} />

          <Flex gap={16}>
            {params.id && orderImport?.$permissions?.create_orders && <PollingButton uid={params.id} />}
            {orderImport?.uploaded_file.uid && orderImport?.$permissions?.download_file && (
              <Tooltip title={orderImport?.uploaded_file?.filename}>
                <DownloadButton uid={orderImport?.uid} upload_uid={orderImport?.uploaded_file?.uid} />
              </Tooltip>
            )}
          </Flex>
        </Row>
      </Layout.Header>
      <Container>
        {idOrderImportLoading || isorderImportDataLoading || !orderImport || !orderImportData?.items.length ? (
          <Spin />
        ) : (
          <Flex vertical>
            <Table
              size="small"
              onChange={(pagination, filters, sorter) => {
                setTableParams({
                  pagination,
                  filters,
                  ...sorter,
                });
              }}
              pagination={{
                ...tableParams.pagination,
                total: orderImportData?.total,
                showQuickJumper: true,
                showSizeChanger: true,
                position: ['bottomCenter'],
                showTotal: (total, range) => `${range[0]}-${range[1]} ${commonT.t('paginationOf')} ${total}`,
              }}
              columns={getColumns({
                onEditClick,
                orderImport,
                colorPrimary: token.colorPrimary,
                onOrderClick,
                t: orderImportsT.t,
              })}
              scroll={{ x: 'auto' }}
              dataSource={orderImportData.items}
            />
          </Flex>
        )}
      </Container>
      {orderImport && activeRow && (
        <RowEditModal open={editOpen} setOpen={setEditOpen} activeRow={activeRow} orderImport={orderImport} />
      )}
    </Layout>
  );
};
