import React, { ReactElement } from 'react';

import { CheckOutlined, CloseOutlined, EditTwoTone, SnippetsTwoTone } from '@ant-design/icons';
import { Flex, Tooltip } from 'antd';
import { ColumnsType } from 'antd/es/table';
import { isObject } from 'lodash';

import { Namespace, TFunction } from 'react-i18next';

import { ImportedDataDTO, ImportFieldDTO, OrderImportDataSchema, OrderImportSchema } from '@app/models/orders';
import { formatDate, formatDateAndTime } from '@app/services/date';

export const getColumns = ({
  onEditClick,
  orderImport,
  colorPrimary,
  onOrderClick,
  t,
}: {
  onEditClick: (row: OrderImportDataSchema) => void;
  onOrderClick: (row: OrderImportDataSchema) => void;
  orderImport: OrderImportSchema;
  colorPrimary: string;
  t: TFunction<Namespace<'orderImports'>>;
}): ColumnsType<OrderImportDataSchema> => {
  const parsedColumns = orderImport.fields?.map((field, index) => ({
    title: field.name,
    key: index,
    dataIndex: field.field_key,
    data: 'el.data',
    render: (value: any, el: OrderImportDataSchema) => {
      const fieldData = el.fields.find((fieldData) => fieldData.field_uid === field.uid)?.data;

      return (
        <div className={`cell ${fieldData?.is_success ? 'success' : 'notSuccess'}`}>
          {fieldData?.is_success ? getValue(field, fieldData) : getInitialValue(fieldData)}
        </div>
      );
    },
  }));
  return parsedColumns
    ? [
        ...parsedColumns,
        {
          title: t('tableColumns.project'),
          dataIndex: 'project',
          key: 'project',
          render: (_, row) => orderImport?.project?.title || '',
        },
        {
          title: '',
          align: 'center',
          dataIndex: '',
          key: 'x',
          render: (_, row) => (
            <Flex gap={16}>
              {row.order_uid && (
                <Tooltip title={t('navigateToOrderTooltip')}>
                  <SnippetsTwoTone twoToneColor={colorPrimary} onClick={() => onOrderClick(row)} />
                </Tooltip>
              )}
              {row?.$permissions?.manage && !row.order_uid ? (
                <Tooltip title={t('tableEditTooltip')}>
                  <EditTwoTone twoToneColor={colorPrimary} onClick={() => onEditClick(row)} />
                </Tooltip>
              ) : null}
            </Flex>
          ),
        },
      ]
    : [];
};

const getValue = ({ field_type }: ImportFieldDTO, fieldData?: ImportedDataDTO): string | ReactElement => {
  if (field_type === 'date') {
    return formatDate(fieldData?.result);
  }

  if (field_type === 'datetime') {
    return formatDateAndTime(fieldData?.result);
  }

  if (field_type === 'contact') {
    return fieldData?.result?.name && fieldData?.result?.phone
      ? `${fieldData?.result?.name} (${fieldData?.result?.phone})`
      : fieldData?.result?.name || '—';
  }

  if (field_type === 'recipient') {
    return fieldData?.result?.name && fieldData?.result?.address?.as_string
      ? `${fieldData?.result?.name} (${fieldData?.result?.address?.as_string})`
      : fieldData?.result?.name || '—';
  }

  if (field_type === 'comment' || field_type === 'string' || field_type === 'text' || field_type === 'num') {
    return fieldData?.result || '—';
  }

  if (field_type === 'bool') {
    return fieldData?.result ? <CheckOutlined /> : <CloseOutlined />;
  }

  return '—';
};

const getInitialValue = (fieldData?: ImportedDataDTO): string => {
  if (!fieldData?.initial) return '—';

  if (isObject(fieldData?.initial)) {
    return getObjectValue(fieldData?.initial as any) || '—';
  }

  return fieldData?.initial?.toString() || '—';
};

const getObjectValue = (obj: Record<string, unknown>): string => {
  const res: unknown[] = [];

  const reduce = (data: Record<string, unknown>) => {
    Object.keys(data).forEach((key) => {
      const value = data[key];
      if (isObject(value)) {
        reduce(value as any);
      } else {
        res.push(value?.toString());
      }
    });
  };

  reduce(obj);

  return res.join('; ');
};
