import React, { ForwardedRef, forwardRef, useState } from 'react';

import { UserOutlined } from '@ant-design/icons';

import { Avatar, Flex, Select, SelectProps, Space } from 'antd';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

import { useUsersQuery } from './hooks/useUsersQuery';

interface IProps extends Omit<SelectProps, 'onChange' | 'value' | 'placeholder'> {
  id?: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(({ value, label = '', operations, onChange, ...props }, ref) => {
  const [operationValue, setOperationValue] = useState<EOperations | undefined>();
  const [searchValue, setSearchValue] = useState<any>('');
  const [inputValue, setInputValue] = useState<any>(value?.value);
  const { data, hasNextPage, fetchNextPage, isFetching } = useUsersQuery({
    limit: 10,
    filters: { name__contains: searchValue },
  });

  const dataSource = data?.pages
    ?.map((data) => data.items || [])
    .flat()
    ?.map((el) => ({
      label: `${el.first_name} ${el.last_name}`,
      value: el.pk,
      data: el,
    }));

  const triggerChange = (changedValue: IInputFilterValue) => {
    onChange?.({ ...value, ...changedValue });
  };

  const handleChange = (_: any[], values: any[]) => {
    const res = values?.map((el) => ({ value: el.value, label: el.label, data: el.data })) as any;
    setInputValue(res);
    triggerChange({ value: res });
    setSearchValue('');
  };

  const onOptionChange = (operation: EOperations) => {
    setOperationValue(operation);
    setSearchValue(undefined);
    setInputValue(undefined);
    triggerChange({ operation, value: undefined });
  };

  React.useEffect(() => {
    if (!value?.operation && operations?.length) {
      onChange?.({ ...value, operation: operations[0] });
    } else if (value?.operation) {
      setOperationValue(value.operation);
    }
  }, []);

  return (
    <Flex vertical gap={4}>
      {operations?.length && (
        <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
      )}
      {operationValue !== EOperations.EX && operationValue !== EOperations.NEX && (
        <Select
          value={inputValue}
          mode="multiple"
          maxCount={value?.operation === EOperations.IN || value?.operation === EOperations.OUT ? undefined : 1}
          labelInValue={true}
          dropdownStyle={{ position: 'fixed' }}
          allowClear
          searchValue={searchValue}
          onPopupScroll={(e: any) => {
            const { target } = e;
            if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
              fetchNextPage();
            }
          }}
          style={{ maxWidth: 400 }}
          options={dataSource}
          showSearch
          onSearch={setSearchValue}
          filterOption={false}
          optionRender={(el: any) => {
            return (
              <Space style={{ display: 'flex', alignItems: 'center', verticalAlign: 'center' }}>
                <Avatar size={25} src={el?.data?.data?.avatar?.variants?.small?.url || ''} icon={<UserOutlined />} />
                {`${el?.data?.data?.first_name} ${el?.data?.data?.last_name}`}
              </Space>
            );
          }}
          onChange={(value, option) => handleChange(value, option as any)}
          {...props}
        />
      )}
    </Flex>
  );
});

Component.displayName = 'FilterUser';
