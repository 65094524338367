import React, { FC } from 'react';

import { Button, Form, Layout, message, Row, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';

import { NSKeys } from '@app/i18n';
import { getFormErrorsFromResponse } from '@app/services/getFormErrorsFromResponse';
import { Breadcrumbs } from '@components/Breadcrumbs';
import { FormSkeleton } from '@components/FormSkeleton';
import { PageTitle } from '@components/PageTitle';

import { FormCatalog, IFormValues } from '../../components/Form';
import { useGetCatalogQuery, useGetCatalogSettingsQuery, useUpdateCatalogQuery } from '../../hooks';

export const Edit: FC = () => {
  const params = useParams<{ id: string }>();
  const commonT = useTranslation(NSKeys.common);
  const catalogsT = useTranslation(NSKeys.catalogs);
  const validationT = useTranslation(NSKeys.validation);
  const navigate = useNavigate();
  const [form] = Form.useForm<IFormValues>();
  const goBack = () => navigate('/dashboard/catalogs');
  const { data: catalog, isLoading: isCatalogLoading, isError: isCatalogError } = useGetCatalogQuery(params.id || '');
  const {
    data: settings,
    isLoading: settingsLoading,
    isError: isSettingsError,
  } = useGetCatalogSettingsQuery(params.id);
  const { mutate: updateCatalog, status: updateStatus, failureReason } = useUpdateCatalogQuery();

  React.useEffect(() => {
    if (isCatalogError || isSettingsError) {
      goBack();
      message.error(catalogsT.t('errors.notFound'));
    }
  }, [isCatalogError, isSettingsError]);

  React.useEffect(() => {
    if (updateStatus === 'success') {
      goBack();
    }
  }, [updateStatus]);

  React.useEffect(() => {
    if (failureReason) {
      const formValues = form.getFieldsValue();
      const { pointerErrors } = getFormErrorsFromResponse(formValues, failureReason, validationT.t);
      if (pointerErrors.length) {
        form.setFields(pointerErrors);
      }
    }
  }, [failureReason]);

  const handleSubmit = async (formValues: IFormValues): Promise<void> => {
    const uids = formValues.media?.map((el) => el?.response?.uid);

    updateCatalog({
      id: params.id || '',
      data: {
        name: formValues.name,
        process_uid: formValues?.process_uid || null,
        warehouse_uid: formValues?.warehouse_uid || null,
        project_uid: formValues?.project_uid ? formValues.project_uid : null,
        cover_uid: uids?.[0] || catalog?.cover?.uid,
        settings: {
          visibility_rule: formValues.visibility_rule,
          order_approval_rule: formValues.order_approval_rule,
          order_approval_chain_uid: formValues.order_approval_chain_uid,
          ...(formValues.visible_to_user_ids?.length && { visible_to_user_ids: formValues.visible_to_user_ids }),
          ...(formValues.visible_to_user_group_uids?.length && {
            visible_to_user_group_uids: formValues.visible_to_user_group_uids,
          }),
        },
      },
    });
  };

  return (
    <>
      <Layout.Header>
        <Breadcrumbs />
        <Row justify="space-between" align="middle">
          <PageTitle title={catalogsT.t('titleEdit')} onClick={goBack} />
          <Space size="middle">
            <Button
              disabled={updateStatus === 'loading'}
              type="primary"
              size="middle"
              form="catalogForm"
              htmlType="submit">
              {commonT.t('buttonSave')}
            </Button>
            <Button onClick={goBack} size="middle">
              {commonT.t('buttonCancel')}
            </Button>
          </Space>
        </Row>
      </Layout.Header>
      {isCatalogLoading || settingsLoading ? (
        <FormSkeleton />
      ) : (
        <FormCatalog
          form={form}
          onFinish={handleSubmit}
          initial_user_group_options={settings?.visible_to_user_groups?.map((el) => ({
            label: el.name,
            value: el.uid,
          }))}
          initial_order_approval_chain_options={[
            { value: settings?.order_approval_chain?.uid || '', label: settings?.order_approval_chain?.name || '' },
          ]}
          initialValues={{
            name: catalog?.name || '',
            warehouse_uid: catalog?.warehouse?.uid,
            visibility_rule: settings?.visibility_rule,
            order_approval_rule: settings?.order_approval_rule,
            project_uid: catalog?.project?.uid,
            visible_to_user_ids: settings?.visible_to_users?.map((el) => el.id),
            visible_to_user_group_uids: settings?.visible_to_user_groups?.map((el) => el.uid),
            order_approval_chain_uid: settings?.order_approval_chain?.uid,
            process_uid: catalog?.process?.uid,
            media: [{ uid: catalog?.cover?.uid, status: 'done', url: catalog?.cover?.variants?.thumbnail?.url }],
          }}
        />
      )}
    </>
  );
};
