import { useQuery } from '@tanstack/react-query';

import Api from '@app/services/api';

export const useGetProjectQuery = (project_uid: string) => {
  return useQuery({
    queryKey: ['getProject', project_uid],
    queryFn: () => Api.orders.getProject(project_uid),
    enabled: !!project_uid,
  });
};
