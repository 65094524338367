import { useQuery } from '@tanstack/react-query';

import { IProjectsParams } from '@app/models/interfaces';
import Api from '@app/services/api';

export const useGetProjectsQuery = ({ limit, offset, process_uid, is_archived }: IProjectsParams) => {
  return useQuery({
    queryKey: ['getProjects', limit, offset, process_uid, is_archived],
    queryFn: () => Api.orders.getProjects({ limit, offset, process_uid, is_archived }),
  });
};
