import React from 'react';

import { FolderOutlined } from '@ant-design/icons';
import { Button, Form, Popover } from 'antd';
import { useTranslation } from 'react-i18next';
import { useDebounceCallback } from 'usehooks-ts';

import { NSKeys } from '@app/i18n';

import { ProjectSelect } from '@components/AntFields';

import { useMultipleMoveToProjectQuery } from '../../../../../../../../../../hooks';

export interface IFormValues {
  projectUid?: string;
}

interface IProps {
  orders: { uid: string; version: string }[];
  process_uid?: string;
  onDone: () => void;
}

export const MultipleChangeProject: React.FC<IProps> = ({ process_uid, orders, onDone }) => {
  const [open, setOpen] = React.useState(false);
  const projectsT = useTranslation(NSKeys.projects);
  const { mutate, status } = useMultipleMoveToProjectQuery();

  const [form] = Form.useForm<IFormValues>();

  const handleSubmit = (values: IFormValues) => {
    mutate({
      data: {
        project: values.projectUid ? { uid: values.projectUid } : null,
        orders: orders?.map((el) => ({ uid: el.uid })),
      },
    });
  };

  React.useEffect(() => {
    if (status === 'success') {
      onDone();
    }
  }, [status]);

  const debouncedChange = useDebounceCallback((values?: IFormValues) => {
    handleSubmit(values as IFormValues);
  }, 300);

  return (
    <Form<IFormValues>
      style={{ display: 'inline-flex', alignItems: 'center' }}
      form={form}
      onFinish={handleSubmit}
      name="project_select"
      onValuesChange={(_, values) => {
        debouncedChange(values);
      }}
      layout="vertical">
      <Popover
        placement="bottomLeft"
        arrow={false}
        destroyTooltipOnHide={true}
        content={
          <Form.Item<IFormValues> name="projectUid" style={{ minWidth: '150px', margin: 0 }}>
            <ProjectSelect
              size="small"
              process_uid={process_uid}
              dropdownStyle={{ position: 'fixed' }}
              placeholder={projectsT.t('filters.project')}
              onChange={() => {
                setOpen(false);
              }}
            />
          </Form.Item>
        }
        trigger="click"
        open={open}
        onOpenChange={setOpen}>
        <Button
          disabled={status === 'loading'}
          size="small"
          onClick={(e) => {
            setOpen(true);
          }}>
          <Form.Item<IFormValues> name="projectUid" style={{ display: 'none' }}>
            <ProjectSelect placeholder="" />
          </Form.Item>
          <Button size="small" type="link" icon={<FolderOutlined />}>
            {projectsT.t('moveToProject')}
          </Button>
        </Button>
      </Popover>
    </Form>
  );
};
