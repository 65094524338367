import React from 'react';

import { Divider, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';

import { MultipleChangeProject } from './components/MultipleChangeProject';
import { MultipleChangeStatus } from './components/MultipleChangeStatus';

interface IProps {
  selectedRows: { uid: string; version: string }[];
  onDone: () => void;
  process_uid: string;
}

export const RowsActions: React.FC<IProps> = ({ selectedRows, onDone, process_uid }) => {
  const commonT = useTranslation(NSKeys.common);

  return (
    <Flex align="center" justify={'flex-start'} style={{ marginTop: 24, marginBottom: 16, position: 'sticky' }} gap={8}>
      <Typography.Text>
        {selectedRows.length} {commonT.t('selected')}
      </Typography.Text>
      <Divider type="vertical" />
      <MultipleChangeStatus orders={selectedRows} onDone={onDone} />
      <MultipleChangeProject orders={selectedRows} onDone={onDone} process_uid={process_uid} />
    </Flex>
  );
};
