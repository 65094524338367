import { useMutation, useQueryClient } from '@tanstack/react-query';
import { message } from 'antd';

import { useTranslation } from 'react-i18next';

import { NSKeys } from '@app/i18n';
import { CreateProjectDTO } from '@app/models/orders';
import Api from '@app/services/api';

export const useCreateProjectQuery = () => {
  const client = useQueryClient();
  const commonT = useTranslation(NSKeys.common);

  return useMutation({
    mutationFn: async (data: CreateProjectDTO) => {
      const res = await Api.orders.postCreateProject(data);
      await client.invalidateQueries(['getProjects']);
      return res;
    },
    onSuccess: async () => {
      message.open({
        type: 'success',
        content: commonT.t('dataAddSuccess'),
      });
    },
    onError: () => {
      message.open({
        type: 'error',
        content: commonT.t('error400'),
      });
    },
  });
};
