import React, { ForwardedRef, forwardRef, useState } from 'react';

import { Flex, Select, SelectProps } from 'antd';

import { EOperations, IInputFilterValue } from '../../index';
import { FilterOperations } from '../FilterOperations';

import { useGetProjectsQuery } from './hooks';

interface IProps extends Omit<SelectProps, 'onChange' | 'value' | 'placeholder'> {
  processUid: string;
  value?: IInputFilterValue;
  label?: string;
  onChange?: (value: IInputFilterValue) => void;
  ref?: ForwardedRef<any>;
  operations?: EOperations[];
}

export const Component = forwardRef<any, IProps>(
  ({ processUid, value, label = '', operations, onChange, ...props }, ref) => {
    const [operationValue, setOperationValue] = useState<EOperations | undefined>();
    const [inputValue, setInputValue] = useState<any>(value?.value);
    const { data, hasNextPage, isFetching, fetchNextPage } = useGetProjectsQuery({
      limit: 10,
      process_uid: processUid,
      enabled: operationValue !== EOperations.EX && operationValue !== EOperations.NEX,
    });

    const dataSource =
      data?.pages
        ?.map((data) => data.items || [])
        .flat()
        ?.map((el) => ({ data: el, label: el.title, value: el.uid })) || [];

    const triggerChange = (changedValue: IInputFilterValue) => {
      onChange?.({ ...value, ...changedValue });
    };

    const handleChange = (_: any[], values: any[]) => {
      const res = values?.map((el) => ({ value: el.value, label: el.label, data: el.data })) as any;
      setInputValue(res);
      triggerChange({ value: res });
    };

    const onOptionChange = (operation: EOperations) => {
      setOperationValue(operation);
      setInputValue(undefined);
      triggerChange({ operation, value: undefined });
    };

    React.useEffect(() => {
      if (!value?.operation && operations?.length) {
        onChange?.({ ...value, operation: operations[0] });
      } else if (value?.operation) {
        setOperationValue(value.operation);
      }
    }, []);

    return (
      <Flex vertical gap={4}>
        {operations?.length && (
          <FilterOperations value={operationValue} onChange={onOptionChange} label={label} operations={operations} />
        )}
        {operationValue !== EOperations.EX && operationValue !== EOperations.NEX && (
          <Select
            value={inputValue}
            loading={isFetching}
            mode="multiple"
            maxCount={value?.operation === EOperations.IN || value?.operation === EOperations.OUT ? undefined : 1}
            labelInValue={true}
            dropdownStyle={{ position: 'fixed' }}
            allowClear
            showSearch={false}
            style={{ maxWidth: 400 }}
            filterOption={false}
            options={dataSource}
            onPopupScroll={(e: any) => {
              const { target } = e;
              if (target?.scrollTop + 10 + target?.offsetHeight > target?.scrollHeight && hasNextPage && !isFetching) {
                fetchNextPage();
              }
            }}
            onChange={(value, option) => handleChange(value, option as any)}
            {...props}
          />
        )}
      </Flex>
    );
  }
);

Component.displayName = 'ProjectSelect';
